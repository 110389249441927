const data = `name,iso2,iso3,flag,dial,year,currencyCode,localPrice,dollarPrice,dollarEx
Australia,AU,AUS,🇦🇺,+61,1986,AUD,1.75,1.06707317073171,1.64
Brazil,BR,BRA,🇧🇷,+55,1986,BRC,2.5,0.181159420289855,13.8
Britain,GB,GBR,🇬🇧,+44,1986,GBP,1.1,1.64179104477612,0.67
Canada,CA,CAN,🇨🇦,+1,1986,CAD,1.89,1.35971223021583,1.39
Hong Kong,HK,HKG,🇭🇰,+852,1986,HKD,7.6,0.974358974358974,7.8
Japan,JP,JPN,🇯🇵,+81,1986,JPY,370,2.4025974025974,154
Singapore,SG,SGP,🇸🇬,+65,1986,SGD,2.8,1.30232558139535,2.15
Sweden,SE,SWE,🇸🇪,+46,1986,SEK,16.5,2.40174672489083,6.87
United States,US,USA,🇺🇸,+1,1986,USD,1.6,1.6,1
Britain,GB,GBR,🇬🇧,+44,1987,GBP,1.13,1.66361111115732,0.679245283
Denmark,DK,DNK,🇩🇰,+45,1987,DKK,21.5,2.99081364841171,7.188679245
United States,US,USA,🇺🇸,+1,1987,USD,1.6,1.6,1
Australia,AU,AUS,🇦🇺,+61,1988,AUD,1.95,1.43382352941176,1.36
Britain,GB,GBR,🇬🇧,+44,1988,GBP,1.19,2.2037037037037,0.54
Canada,CA,CAN,🇨🇦,+1,1988,CAD,2.05,1.65322580645161,1.24
Denmark,DK,DNK,🇩🇰,+45,1988,DKK,22.75,3.57704402515723,6.36
Hong Kong,HK,HKG,🇭🇰,+852,1988,HKD,7.6,0.974358974358974,7.8
Japan,JP,JPN,🇯🇵,+81,1988,JPY,370,2.98387096774194,124
Singapore,SG,SGP,🇸🇬,+65,1988,SGD,2.8,1.4,2
Sweden,SE,SWE,🇸🇪,+46,1988,SEK,18.5,3.14091680814941,5.89
United States,US,USA,🇺🇸,+1,1988,USD,2.39,2.39,1
Australia,AU,AUS,🇦🇺,+61,1989,AUD,2.1,1.69354838709677,1.24
Britain,GB,GBR,🇬🇧,+44,1989,GBP,1.26,2.13559322033898,0.59
Canada,CA,CAN,🇨🇦,+1,1989,CAD,2.15,1.80672268907563,1.19
Denmark,DK,DNK,🇩🇰,+45,1989,DKK,24.75,3.37653478854025,7.33
Hong Kong,HK,HKG,🇭🇰,+852,1989,HKD,7.6,0.976863753213367,7.78
Japan,JP,JPN,🇯🇵,+81,1989,JPY,370,2.78195488721805,133
Singapore,SG,SGP,🇸🇬,+65,1989,SGD,2.8,1.42857142857143,1.96
South Korea,KR,KOR,🇰🇷,+82,1989,KRW,2400,3.6036036036036,666
Sweden,SE,SWE,🇸🇪,+46,1989,SEK,21,3.27613104524181,6.41
United States,US,USA,🇺🇸,+1,1989,USD,2.02,2.02,1
Australia,AU,AUS,🇦🇺,+61,1990,AUD,2.3,1.74242424242424,1.32
Britain,GB,GBR,🇬🇧,+44,1990,GBP,1.4,2.29508196721311,0.61
Canada,CA,CAN,🇨🇦,+1,1990,CAD,2.19,1.88793103448276,1.16
Denmark,DK,DNK,🇩🇰,+45,1990,DKK,25.5,3.9906103286385,6.39
Hong Kong,HK,HKG,🇭🇰,+852,1990,HKD,8.6,1.10397946084724,7.79
Japan,JP,JPN,🇯🇵,+81,1990,JPY,370,2.32704402515723,159
Singapore,SG,SGP,🇸🇬,+65,1990,SGD,2.6,1.38297872340426,1.88
South Korea,KR,KOR,🇰🇷,+82,1990,KRW,2100,2.97029702970297,707
Sweden,SE,SWE,🇸🇪,+46,1990,SEK,24,3.9344262295082,6.1
United States,US,USA,🇺🇸,+1,1990,USD,2.2,2.2,1
Australia,AU,AUS,🇦🇺,+61,1991,AUD,2.45,1.92913385826772,1.27
Britain,GB,GBR,🇬🇧,+44,1991,GBP,1.67,2.98214285714286,0.56
Canada,CA,CAN,🇨🇦,+1,1991,CAD,2.35,2.04347826086957,1.15
Denmark,DK,DNK,🇩🇰,+45,1991,DKK,26.75,4.16666666666667,6.42
Hong Kong,HK,HKG,🇭🇰,+852,1991,HKD,8.9,1.14249037227214,7.79
Hungary,HU,HUN,🇭🇺,+36,1991,HUF,115,1.53088391906283,75.12
Japan,JP,JPN,🇯🇵,+81,1991,JPY,380,2.81481481481482,135
Singapore,SG,SGP,🇸🇬,+65,1991,SGD,2.8,1.5819209039548,1.77
South Korea,KR,KOR,🇰🇷,+82,1991,KRW,2100,2.9126213592233,721
Sweden,SE,SWE,🇸🇪,+46,1991,SEK,26,4.3046357615894,6.04
United States,US,USA,🇺🇸,+1,1991,USD,2.25,2.25,1
Argentina,AR,ARG,🇦🇷,+54,1992,ARS,3.3,3.33333333333333,0.99
Australia,AU,AUS,🇦🇺,+61,1992,AUD,2.54,1.93893129770992,1.31
Brazil,BR,BRA,🇧🇷,+55,1992,BRE,3800,1.76497909893172,2153
Britain,GB,GBR,🇬🇧,+44,1992,GBP,1.74,3.05263157894737,0.57
Canada,CA,CAN,🇨🇦,+1,1992,CAD,2.76,2.31932773109244,1.19
China,CN,CHN,🇨🇳,+86,1992,CNY,6.3,1.15808823529412,5.44
Denmark,DK,DNK,🇩🇰,+45,1992,DKK,27.25,4.31170886075949,6.32
Hong Kong,HK,HKG,🇭🇰,+852,1992,HKD,8.9,1.15135834411384,7.73
Hungary,HU,HUN,🇭🇺,+36,1992,HUF,133,1.66875784190715,79.7
Japan,JP,JPN,🇯🇵,+81,1992,JPY,380,2.85714285714286,133
Russia,RU,RUS,🇷🇺,+7,1992,RUR,58,0.586154623547246,98.95
Singapore,SG,SGP,🇸🇬,+65,1992,SGD,4.75,2.87878787878788,1.65
South Korea,KR,KOR,🇰🇷,+82,1992,KRW,2300,2.95629820051414,778
Sweden,SE,SWE,🇸🇪,+46,1992,SEK,25.5,4.30016863406408,5.93
United States,US,USA,🇺🇸,+1,1992,USD,2.19,2.19,1
Venezuela,VE,VEN,🇻🇪,+58,1992,VEB,170,2.80389246247732,60.63
Argentina,AR,ARG,🇦🇷,+54,1993,ARS,3.6,3.6,1
Australia,AU,AUS,🇦🇺,+61,1993,AUD,2.45,1.76258992805755,1.39
Brazil,BR,BRA,🇧🇷,+55,1993,BRE,77000,2.7978634497293,27521
Britain,GB,GBR,🇬🇧,+44,1993,GBP,1.79,2.7924000001117,0.641025641
Canada,CA,CAN,🇨🇦,+1,1993,CAD,2.76,2.19047619047619,1.26
China,CN,CHN,🇨🇳,+86,1993,CNY,8.5,1.49647887323944,5.68
Denmark,DK,DNK,🇩🇰,+45,1993,DKK,25.75,4.24917491749175,6.06
Hong Kong,HK,HKG,🇭🇰,+852,1993,HKD,9,1.16429495472186,7.73
Hungary,HU,HUN,🇭🇺,+36,1993,HUF,157,1.78044908142436,88.18
Japan,JP,JPN,🇯🇵,+81,1993,JPY,391,3.46017699115044,113
Malaysia,MY,MYS,🇲🇾,+60,1993,MYR,3.35,1.2984496124031,2.58
Mexico,MX,MEX,🇲🇽,+52,1993,MXN,7.9,2.54838709677419,3.1
Russia,RU,RUS,🇷🇺,+7,1993,RUR,780,1.13702623906706,686
South Korea,KR,KOR,🇰🇷,+82,1993,KRW,2300,2.8894472361809,796
Sweden,SE,SWE,🇸🇪,+46,1993,SEK,25.5,3.43203230148048,7.43
Switzerland,CH,CHE,🇨🇭,+41,1993,CHF,5.7,3.93103448275862,1.45
Thailand,TH,THA,🇹🇭,+66,1993,THB,48,1.90779014308426,25.16
United States,US,USA,🇺🇸,+1,1993,USD,2.28,2.28,1
Argentina,AR,ARG,🇦🇷,+54,1994,ARS,3.6,3.6,1
Australia,AU,AUS,🇦🇺,+61,1994,AUD,2.45,1.72535211267606,1.42
Brazil,BR,BRA,🇧🇷,+55,1994,BRR,1500,1.56412930135558,959
Britain,GB,GBR,🇬🇧,+44,1994,GBP,1.81,2.64259999941863,0.684931507
Canada,CA,CAN,🇨🇦,+1,1994,CAD,2.86,2.05755395683453,1.39
Chile,CL,CHL,🇨🇱,+56,1994,CLP,948,2.28985507246377,414
China,CN,CHN,🇨🇳,+86,1994,CNY,9,1.03448275862069,8.7
Czech Republic,CZ,CZE,🇨🇿,+420,1994,CZK,50,1.68350168350168,29.7
Denmark,DK,DNK,🇩🇰,+45,1994,DKK,25.75,3.84902840059791,6.69
Hong Kong,HK,HKG,🇭🇰,+852,1994,HKD,9.2,1.1901681759379,7.73
Hungary,HU,HUN,🇭🇺,+36,1994,HUF,169,1.64077669902913,103
Japan,JP,JPN,🇯🇵,+81,1994,JPY,391,3.75961538461538,104
Malaysia,MY,MYS,🇲🇾,+60,1994,MYR,3.77,1.40148698884758,2.69
Mexico,MX,MEX,🇲🇽,+52,1994,MXN,8.1,2.41071428571429,3.36
Poland,PL,POL,🇵🇱,+48,1994,PLN,31000,1.38189274729194,22433
Russia,RU,RUS,🇷🇺,+7,1994,RUR,2900,1.63380281690141,1775
Singapore,SG,SGP,🇸🇬,+65,1994,SGD,2.98,1.89808917197452,1.57
South Korea,KR,KOR,🇰🇷,+82,1994,KRW,2300,2.83950617283951,810
Sweden,SE,SWE,🇸🇪,+46,1994,SEK,25.5,3.19949811794228,7.97
Switzerland,CH,CHE,🇨🇭,+41,1994,CHF,5.7,3.95833333333333,1.44
Taiwan,TW,TWN,🇹🇼,+886,1994,TWD,62,2.34848484848485,26.4
Thailand,TH,THA,🇹🇭,+66,1994,THB,48,1.89723320158103,25.3
United States,US,USA,🇺🇸,+1,1994,USD,2.3,2.3,1
Argentina,AR,ARG,🇦🇷,+54,1995,ARS,3,3,1
Australia,AU,AUS,🇦🇺,+61,1995,AUD,2.45,1.81481481481482,1.35
Brazil,BR,BRA,🇧🇷,+55,1995,BRL,2.45,2.72222222222222,0.9
Britain,GB,GBR,🇬🇧,+44,1995,GBP,1.74,2.80140000190495,0.621118012
Canada,CA,CAN,🇨🇦,+1,1995,CAD,2.77,1.99280575539568,1.39
Chile,CL,CHL,🇨🇱,+56,1995,CLP,950,2.40506329113924,395
China,CN,CHN,🇨🇳,+86,1995,CNY,9,1.05386416861827,8.54
Czech Republic,CZ,CZE,🇨🇿,+420,1995,CZK,50,1.90839694656489,26.2
Denmark,DK,DNK,🇩🇰,+45,1995,DKK,26.75,4.92633517495396,5.43
Hong Kong,HK,HKG,🇭🇰,+852,1995,HKD,9.5,1.22897800776197,7.73
Hungary,HU,HUN,🇭🇺,+36,1995,HUF,191,1.57851239669422,121
Indonesia,ID,IDN,🇮🇩,+62,1995,IDR,3900,1.74809502465262,2231
Israel,IL,ISR,🇮🇱,+972,1995,ILS,8.9,3.01694915254237,2.95
Japan,JP,JPN,🇯🇵,+81,1995,JPY,391,4.6437054631829,84.2
Malaysia,MY,MYS,🇲🇾,+60,1995,MYR,3.76,1.51004016064257,2.49
Mexico,MX,MEX,🇲🇽,+52,1995,MXN,10.9,1.71114599686028,6.37
New Zealand,NZ,NZL,🇳🇿,+64,1995,NZD,2.95,1.95364238410596,1.51
Poland,PL,POL,🇵🇱,+48,1995,PLN,3.4,1.45299145299145,2.34
Russia,RU,RUS,🇷🇺,+7,1995,RUR,8100,1.62487462387161,4985
Singapore,SG,SGP,🇸🇬,+65,1995,SGD,2.95,2.10714285714286,1.4
South Korea,KR,KOR,🇰🇷,+82,1995,KRW,2300,2.99089726918075,769
Sweden,SE,SWE,🇸🇪,+46,1995,SEK,26,3.54223433242507,7.34
Switzerland,CH,CHE,🇨🇭,+41,1995,CHF,5.9,5.2212389380531,1.13
Taiwan,TW,TWN,🇹🇼,+886,1995,TWD,65,2.52918287937743,25.7
Thailand,TH,THA,🇹🇭,+66,1995,THB,48,1.95121951219512,24.6
United States,US,USA,🇺🇸,+1,1995,USD,2.32,2.32,1
Argentina,AR,ARG,🇦🇷,+54,1996,ARS,3,3,1
Australia,AU,AUS,🇦🇺,+61,1996,AUD,2.5,1.96850393700787,1.27
Brazil,BR,BRA,🇧🇷,+55,1996,BRL,2.95,2.97979797979798,0.99
Britain,GB,GBR,🇬🇧,+44,1996,GBP,1.79,2.70289999848638,0.662251656
Canada,CA,CAN,🇨🇦,+1,1996,CAD,2.86,2.10294117647059,1.36
Chile,CL,CHL,🇨🇱,+56,1996,CLP,950,2.32843137254902,408
China,CN,CHN,🇨🇳,+86,1996,CNY,9.6,1.1497005988024,8.35
Czech Republic,CZ,CZE,🇨🇿,+420,1996,CZK,51,1.84782608695652,27.6
Denmark,DK,DNK,🇩🇰,+45,1996,DKK,25.75,4.4017094017094,5.85
Hong Kong,HK,HKG,🇭🇰,+852,1996,HKD,9.9,1.27906976744186,7.74
Hungary,HU,HUN,🇭🇺,+36,1996,HUF,214,1.42666666666667,150
Israel,IL,ISR,🇮🇱,+972,1996,ILS,9.5,2.99684542586751,3.17
Japan,JP,JPN,🇯🇵,+81,1996,JPY,288,2.69158878504673,107
Malaysia,MY,MYS,🇲🇾,+60,1996,MYR,3.76,1.51004016064257,2.49
Mexico,MX,MEX,🇲🇽,+52,1996,MXN,14.9,2.02170963364993,7.37
New Zealand,NZ,NZL,🇳🇿,+64,1996,NZD,2.95,2.00680272108844,1.47
Poland,PL,POL,🇵🇱,+48,1996,PLN,3.8,1.43939393939394,2.64
Russia,RU,RUS,🇷🇺,+7,1996,RUR,9500,1.9316795445303,4918
Singapore,SG,SGP,🇸🇬,+65,1996,SGD,3.05,2.16312056737589,1.41
South Africa,ZA,ZAF,🇿🇦,+27,1996,ZAR,7,1.64319248826291,4.26
South Korea,KR,KOR,🇰🇷,+82,1996,KRW,2300,2.95250320924262,779
Sweden,SE,SWE,🇸🇪,+46,1996,SEK,26,3.87481371087929,6.71
Switzerland,CH,CHE,🇨🇭,+41,1996,CHF,5.9,4.79674796747968,1.23
Taiwan,TW,TWN,🇹🇼,+886,1996,TWD,65,2.38970588235294,27.2
Thailand,TH,THA,🇹🇭,+66,1996,THB,48,1.89723320158103,25.3
United States,US,USA,🇺🇸,+1,1996,USD,2.36,2.36,1
Argentina,AR,ARG,🇦🇷,+54,1997,ARS,2.5,2.5,1
Australia,AU,AUS,🇦🇺,+61,1997,AUD,2.5,1.93798449612403,1.29
Brazil,BR,BRA,🇧🇷,+55,1997,BRL,2.97,2.80188679245283,1.06
Britain,GB,GBR,🇬🇧,+44,1997,GBP,1.81,2.95029999766926,0.613496933
Canada,CA,CAN,🇨🇦,+1,1997,CAD,2.88,2.07194244604317,1.39
Chile,CL,CHL,🇨🇱,+56,1997,CLP,1200,2.87769784172662,417
China,CN,CHN,🇨🇳,+86,1997,CNY,9.7,1.16446578631453,8.33
Czech Republic,CZ,CZE,🇨🇿,+420,1997,CZK,53,1.81506849315069,29.2
Denmark,DK,DNK,🇩🇰,+45,1997,DKK,25.75,3.94938650306749,6.52
Hong Kong,HK,HKG,🇭🇰,+852,1997,HKD,9.9,1.27741935483871,7.75
Hungary,HU,HUN,🇭🇺,+36,1997,HUF,271,1.52247191011236,178
Israel,IL,ISR,🇮🇱,+972,1997,ILS,11.5,3.40236686390533,3.38
Japan,JP,JPN,🇯🇵,+81,1997,JPY,294,2.33333333333333,126
Malaysia,MY,MYS,🇲🇾,+60,1997,MYR,3.87,1.548,2.5
Mexico,MX,MEX,🇲🇽,+52,1997,MXN,14.9,1.88607594936709,7.9
New Zealand,NZ,NZL,🇳🇿,+64,1997,NZD,3.25,2.24137931034483,1.45
Poland,PL,POL,🇵🇱,+48,1997,PLN,4.3,1.38709677419355,3.1
Russia,RU,RUS,🇷🇺,+7,1997,RUR,11000,1.91671022826276,5739
Singapore,SG,SGP,🇸🇬,+65,1997,SGD,3,2.08333333333333,1.44
South Africa,ZA,ZAF,🇿🇦,+27,1997,ZAR,7.8,1.7607223476298,4.43
South Korea,KR,KOR,🇰🇷,+82,1997,KRW,2300,2.57270693512304,894
Sweden,SE,SWE,🇸🇪,+46,1997,SEK,26,3.36787564766839,7.72
Switzerland,CH,CHE,🇨🇭,+41,1997,CHF,5.9,4.01360544217687,1.47
Taiwan,TW,TWN,🇹🇼,+886,1997,TWD,68,2.46376811594203,27.6
Thailand,TH,THA,🇹🇭,+66,1997,THB,46.7,1.78927203065134,26.1
United States,US,USA,🇺🇸,+1,1997,USD,2.42,2.42,1
Argentina,AR,ARG,🇦🇷,+54,1998,ARS,2.5,2.5,1
Australia,AU,AUS,🇦🇺,+61,1998,AUD,2.65,1.75496688741722,1.51
Brazil,BR,BRA,🇧🇷,+55,1998,BRL,3.1,2.71929824561404,1.14
Britain,GB,GBR,🇬🇧,+44,1998,GBP,1.84,3.05439999773974,0.602409639
Canada,CA,CAN,🇨🇦,+1,1998,CAD,2.79,1.96478873239437,1.42
Chile,CL,CHL,🇨🇱,+56,1998,CLP,1250,2.74725274725275,455
China,CN,CHN,🇨🇳,+86,1998,CNY,9.9,1.19565217391304,8.28
Czech Republic,CZ,CZE,🇨🇿,+420,1998,CZK,54,1.56976744186047,34.4
Denmark,DK,DNK,🇩🇰,+45,1998,DKK,23.8,3.39031339031339,7.02
Hong Kong,HK,HKG,🇭🇰,+852,1998,HKD,10.2,1.31612903225806,7.75
Hungary,HU,HUN,🇭🇺,+36,1998,HUF,259,1.21596244131455,213
Indonesia,ID,IDN,🇮🇩,+62,1998,IDR,9900,1.16470588235294,8500
Israel,IL,ISR,🇮🇱,+972,1998,ILS,12.5,3.37837837837838,3.7
Japan,JP,JPN,🇯🇵,+81,1998,JPY,280,2.07407407407407,135
Malaysia,MY,MYS,🇲🇾,+60,1998,MYR,4.3,1.15591397849462,3.72
Mexico,MX,MEX,🇲🇽,+52,1998,MXN,17.9,2.096018735363,8.54
New Zealand,NZ,NZL,🇳🇿,+64,1998,NZD,3.45,1.8956043956044,1.82
Poland,PL,POL,🇵🇱,+48,1998,PLN,5.3,1.53179190751445,3.46
Russia,RU,RUS,🇷🇺,+7,1998,RUR,12000,2.00033338889815,5999
Singapore,SG,SGP,🇸🇬,+65,1998,SGD,3,1.85185185185185,1.62
South Africa,ZA,ZAF,🇿🇦,+27,1998,ZAR,8,1.58730158730159,5.04
South Korea,KR,KOR,🇰🇷,+82,1998,KRW,2600,1.76390773405699,1474
Sweden,SE,SWE,🇸🇪,+46,1998,SEK,24,3,8
Switzerland,CH,CHE,🇨🇭,+41,1998,CHF,5.9,3.88157894736842,1.52
Taiwan,TW,TWN,🇹🇼,+886,1998,TWD,68,2.06060606060606,33
Thailand,TH,THA,🇹🇭,+66,1998,THB,52,1.3,40
United States,US,USA,🇺🇸,+1,1998,USD,2.56,2.56,1
Argentina,AR,ARG,🇦🇷,+54,1999,ARS,2.5,2.5,1
Australia,AU,AUS,🇦🇺,+61,1999,AUD,2.65,1.66666666666667,1.59
Brazil,BR,BRA,🇧🇷,+55,1999,BRL,2.95,1.70520231213873,1.73
Britain,GB,GBR,🇬🇧,+44,1999,GBP,1.9,3.05900000208012,0.621118012
Canada,CA,CAN,🇨🇦,+1,1999,CAD,2.99,1.98013245033113,1.51
Chile,CL,CHL,🇨🇱,+56,1999,CLP,1259,2.60123966942149,484
China,CN,CHN,🇨🇳,+86,1999,CNY,9.9,1.19565217391304,8.28
Denmark,DK,DNK,🇩🇰,+45,1999,DKK,24.75,3.58176555716353,6.91
Euro area,,EUZ,,,1999,EUR,2.52,2.72159999978227,0.925925926
Hong Kong,HK,HKG,🇭🇰,+852,1999,HKD,10.2,1.31612903225806,7.75
Hungary,HU,HUN,🇭🇺,+36,1999,HUF,299,1.26160337552743,237
Indonesia,ID,IDN,🇮🇩,+62,1999,IDR,14500,1.66189111747851,8725
Israel,IL,ISR,🇮🇱,+972,1999,ILS,13.9,3.44059405940594,4.04
Japan,JP,JPN,🇯🇵,+81,1999,JPY,294,2.45,120
Malaysia,MY,MYS,🇲🇾,+60,1999,MYR,4.52,1.18947368421053,3.8
Mexico,MX,MEX,🇲🇽,+52,1999,MXN,19.9,2.08595387840671,9.54
New Zealand,NZ,NZL,🇳🇿,+64,1999,NZD,3.4,1.81818181818182,1.87
Poland,PL,POL,🇵🇱,+48,1999,PLN,5.5,1.38190954773869,3.98
Russia,RU,RUS,🇷🇺,+7,1999,RUB,33.5,1.35627530364372,24.7
Singapore,SG,SGP,🇸🇬,+65,1999,SGD,3.2,1.84971098265896,1.73
South Africa,ZA,ZAF,🇿🇦,+27,1999,ZAR,8.6,1.38263665594855,6.22
South Korea,KR,KOR,🇰🇷,+82,1999,KRW,3000,2.46305418719212,1218
Sweden,SE,SWE,🇸🇪,+46,1999,SEK,24,2.88461538461538,8.32
Switzerland,CH,CHE,🇨🇭,+41,1999,CHF,5.9,3.98648648648649,1.48
Taiwan,TW,TWN,🇹🇼,+886,1999,TWD,70,2.10843373493976,33.2
Thailand,TH,THA,🇹🇭,+66,1999,THB,52,1.38297872340426,37.6
United States,US,USA,🇺🇸,+1,1999,USD,2.43,2.43,1
Argentina,AR,ARG,🇦🇷,+54,2000,ARS,2.5,2.5,1
Australia,AU,AUS,🇦🇺,+61,2000,AUD,2.59,1.54166666666667,1.68
Brazil,BR,BRA,🇧🇷,+55,2000,BRL,2.95,1.64804469273743,1.79
Britain,GB,GBR,🇬🇧,+44,2000,GBP,1.9,3.00200000192128,0.632911392
Canada,CA,CAN,🇨🇦,+1,2000,CAD,2.85,1.93877551020408,1.47
Chile,CL,CHL,🇨🇱,+56,2000,CLP,1260,2.45136186770428,514
China,CN,CHN,🇨🇳,+86,2000,CNY,9.9,1.19565217391304,8.28
Czech Republic,CZ,CZE,🇨🇿,+420,2000,CZK,54.37,1.39053708439898,39.1
Denmark,DK,DNK,🇩🇰,+45,2000,DKK,24.75,3.07835820895522,8.04
Euro area,,EUZ,,,2000,EUR,2.56,2.38080000045235,1.075268817
Hong Kong,HK,HKG,🇭🇰,+852,2000,HKD,10.2,1.30937098844673,7.79
Hungary,HU,HUN,🇭🇺,+36,2000,HUF,339,1.21505376344086,279
Indonesia,ID,IDN,🇮🇩,+62,2000,IDR,14500,1.82504719949654,7945
Israel,IL,ISR,🇮🇱,+972,2000,ILS,14.5,3.58024691358025,4.05
Japan,JP,JPN,🇯🇵,+81,2000,JPY,294,2.77358490566038,106
Malaysia,MY,MYS,🇲🇾,+60,2000,MYR,4.52,1.18947368421053,3.8
Mexico,MX,MEX,🇲🇽,+52,2000,MXN,20.9,2.22104144527099,9.41
New Zealand,NZ,NZL,🇳🇿,+64,2000,NZD,3.4,1.69154228855721,2.01
Poland,PL,POL,🇵🇱,+48,2000,PLN,5.5,1.27906976744186,4.3
Russia,RU,RUS,🇷🇺,+7,2000,RUB,39.5,1.3859649122807,28.5
Singapore,SG,SGP,🇸🇬,+65,2000,SGD,3.2,1.88235294117647,1.7
South Africa,ZA,ZAF,🇿🇦,+27,2000,ZAR,9,1.33928571428571,6.72
South Korea,KR,KOR,🇰🇷,+82,2000,KRW,3000,2.70758122743682,1108
Sweden,SE,SWE,🇸🇪,+46,2000,SEK,24,2.71493212669683,8.84
Switzerland,CH,CHE,🇨🇭,+41,2000,CHF,5.9,3.47058823529412,1.7
Taiwan,TW,TWN,🇹🇼,+886,2000,TWD,70,2.28758169934641,30.6
Thailand,TH,THA,🇹🇭,+66,2000,THB,55,1.44736842105263,38
United States,US,USA,🇺🇸,+1,2000,USD,2.24,2.24,1
Argentina,AR,ARG,🇦🇷,+54,2001,ARS,2.5,2.5,1
Australia,AU,AUS,🇦🇺,+61,2001,AUD,3,1.51515151515152,1.98
Brazil,BR,BRA,🇧🇷,+55,2001,BRL,3.6,1.64383561643836,2.19
Britain,GB,GBR,🇬🇧,+44,2001,GBP,1.99,2.84570000122365,0.699300699
Canada,CA,CAN,🇨🇦,+1,2001,CAD,3.33,2.13461538461538,1.56
Chile,CL,CHL,🇨🇱,+56,2001,CLP,1260,2.09650582362729,601
China,CN,CHN,🇨🇳,+86,2001,CNY,9.9,1.19565217391304,8.28
Czech Republic,CZ,CZE,🇨🇿,+420,2001,CZK,56,1.43589743589744,39
Denmark,DK,DNK,🇩🇰,+45,2001,DKK,24.75,2.92553191489362,8.46
Euro area,,EUZ,,,2001,EUR,2.57,2.26160000072371,1.136363636
Hong Kong,HK,HKG,🇭🇰,+852,2001,HKD,10.7,1.37179487179487,7.8
Hungary,HU,HUN,🇭🇺,+36,2001,HUF,399,1.31683168316832,303
Indonesia,ID,IDN,🇮🇩,+62,2001,IDR,14700,1.35421464762782,10855
Japan,JP,JPN,🇯🇵,+81,2001,JPY,294,2.37096774193548,124
Malaysia,MY,MYS,🇲🇾,+60,2001,MYR,4.52,1.18947368421053,3.8
Mexico,MX,MEX,🇲🇽,+52,2001,MXN,21.9,2.35737351991389,9.29
New Zealand,NZ,NZL,🇳🇿,+64,2001,NZD,3.6,1.45748987854251,2.47
Philippines,PH,PHL,🇵🇭,+63,2001,PHP,59,1.17296222664016,50.3
Poland,PL,POL,🇵🇱,+48,2001,PLN,5.9,1.46401985111663,4.03
Russia,RU,RUS,🇷🇺,+7,2001,RUB,35,1.21107266435986,28.9
Singapore,SG,SGP,🇸🇬,+65,2001,SGD,3.3,1.8232044198895,1.81
South Africa,ZA,ZAF,🇿🇦,+27,2001,ZAR,9.7,1.19311193111931,8.13
South Korea,KR,KOR,🇰🇷,+82,2001,KRW,3000,2.26415094339623,1325
Sweden,SE,SWE,🇸🇪,+46,2001,SEK,24,2.33463035019455,10.28
Switzerland,CH,CHE,🇨🇭,+41,2001,CHF,6.3,3.64161849710983,1.73
Taiwan,TW,TWN,🇹🇼,+886,2001,TWD,70,2.12765957446809,32.9
Thailand,TH,THA,🇹🇭,+66,2001,THB,55,1.20879120879121,45.5
United States,US,USA,🇺🇸,+1,2001,USD,2.24,2.24,1
Argentina,AR,ARG,🇦🇷,+54,2002,ARS,2.5,0.798722044728435,3.13
Australia,AU,AUS,🇦🇺,+61,2002,AUD,3,1.61290322580645,1.86
Brazil,BR,BRA,🇧🇷,+55,2002,BRL,3.6,1.53846153846154,2.34
Britain,GB,GBR,🇬🇧,+44,2002,GBP,1.99,2.8855000017313,0.689655172
Canada,CA,CAN,🇨🇦,+1,2002,CAD,3.33,2.12101910828025,1.57
Chile,CL,CHL,🇨🇱,+56,2002,CLP,1400,2.13740458015267,655
China,CN,CHN,🇨🇳,+86,2002,CNY,10.5,1.26811594202899,8.28
Czech Republic,CZ,CZE,🇨🇿,+420,2002,CZK,56.28,1.65529411764706,34
Denmark,DK,DNK,🇩🇰,+45,2002,DKK,24.75,2.95346062052506,8.38
Euro area,,EUZ,,,2002,EUR,2.67,2.37629999919206,1.123595506
Hong Kong,HK,HKG,🇭🇰,+852,2002,HKD,11.2,1.4,8
Hungary,HU,HUN,🇭🇺,+36,2002,HUF,459,1.6875,272
Indonesia,ID,IDN,🇮🇩,+62,2002,IDR,16000,1.69671261930011,9430
Israel,IL,ISR,🇮🇱,+972,2002,ILS,12,2.50521920668058,4.79
Japan,JP,JPN,🇯🇵,+81,2002,JPY,262,2.01538461538462,130
Malaysia,MY,MYS,🇲🇾,+60,2002,MYR,5.04,1.32631578947368,3.8
Mexico,MX,MEX,🇲🇽,+52,2002,MXN,21.9,2.35991379310345,9.28
New Zealand,NZ,NZL,🇳🇿,+64,2002,NZD,3.6,1.60714285714286,2.24
Norway,NO,NOR,🇳🇴,+47,2002,NOK,35,4.08878504672897,8.56
Peru,PE,PER,🇵🇪,+51,2002,PEN,8.5,2.47813411078717,3.43
Philippines,PH,PHL,🇵🇭,+63,2002,PHP,65,1.27450980392157,51
Poland,PL,POL,🇵🇱,+48,2002,PLN,5.9,1.46039603960396,4.04
Russia,RU,RUS,🇷🇺,+7,2002,RUB,39,1.25,31.2
Singapore,SG,SGP,🇸🇬,+65,2002,SGD,3.3,1.81318681318681,1.82
South Africa,ZA,ZAF,🇿🇦,+27,2002,ZAR,9.7,0.889908256880734,10.9
South Korea,KR,KOR,🇰🇷,+82,2002,KRW,3100,2.37730061349693,1304
Sweden,SE,SWE,🇸🇪,+46,2002,SEK,26,2.5,10.4
Switzerland,CH,CHE,🇨🇭,+41,2002,CHF,6.3,3.79518072289157,1.66
Taiwan,TW,TWN,🇹🇼,+886,2002,TWD,70,2.01149425287356,34.8
Thailand,TH,THA,🇹🇭,+66,2002,THB,55,1.270207852194,43.3
Turkey,TR,TUR,🇹🇷,+90,2002,TRY,4000000,3.02000755001888,1324500
United States,US,USA,🇺🇸,+1,2002,USD,2.35,2.35,1
Venezuela,VE,VEN,🇻🇪,+58,2002,VEF,2500,2.9171528588098,857
Argentina,AR,ARG,🇦🇷,+54,2003,ARS,4.1,1.42361111111111,2.88
Australia,AU,AUS,🇦🇺,+61,2003,AUD,3,1.86335403726708,1.61
Brazil,BR,BRA,🇧🇷,+55,2003,BRL,4.55,1.48208469055375,3.07
Britain,GB,GBR,🇬🇧,+44,2003,GBP,1.99,3.14420000201229,0.632911392
Canada,CA,CAN,🇨🇦,+1,2003,CAD,3.2,2.20689655172414,1.45
Chile,CL,CHL,🇨🇱,+56,2003,CLP,1400,1.95530726256983,716
China,CN,CHN,🇨🇳,+86,2003,CNY,9.9,1.19565217391304,8.28
Czech Republic,CZ,CZE,🇨🇿,+420,2003,CZK,56.57,1.95743944636678,28.9
Denmark,DK,DNK,🇩🇰,+45,2003,DKK,27.75,4.0929203539823,6.78
Egypt,EG,EGY,🇪🇬,+20,2003,EGP,8,1.35135135135135,5.92
Euro area,,EUZ,,,2003,EUR,2.71,2.9810000002981,0.909090909
Hong Kong,HK,HKG,🇭🇰,+852,2003,HKD,11.5,1.47435897435897,7.8
Hungary,HU,HUN,🇭🇺,+36,2003,HUF,490,2.1875,224
Indonesia,ID,IDN,🇮🇩,+62,2003,IDR,16100,1.84210526315789,8740
Japan,JP,JPN,🇯🇵,+81,2003,JPY,262,2.18333333333333,120
Malaysia,MY,MYS,🇲🇾,+60,2003,MYR,5.04,1.32631578947368,3.8
Mexico,MX,MEX,🇲🇽,+52,2003,MXN,23,2.18423551756885,10.53
New Zealand,NZ,NZL,🇳🇿,+64,2003,NZD,3.95,2.21910112359551,1.78
Peru,PE,PER,🇵🇪,+51,2003,PEN,7.9,2.28323699421965,3.46
Philippines,PH,PHL,🇵🇭,+63,2003,PHP,65,1.23809523809524,52.5
Poland,PL,POL,🇵🇱,+48,2003,PLN,6.3,1.61953727506427,3.89
Russia,RU,RUS,🇷🇺,+7,2003,RUB,41,1.31832797427653,31.1
Singapore,SG,SGP,🇸🇬,+65,2003,SGD,3.3,1.85393258426966,1.78
South Africa,ZA,ZAF,🇿🇦,+27,2003,ZAR,13.95,1.8452380952381,7.56
South Korea,KR,KOR,🇰🇷,+82,2003,KRW,3300,2.70491803278689,1220
Sweden,SE,SWE,🇸🇪,+46,2003,SEK,30,3.59712230215827,8.34
Switzerland,CH,CHE,🇨🇭,+41,2003,CHF,6.3,4.5985401459854,1.37
Taiwan,TW,TWN,🇹🇼,+886,2003,TWD,70,2.01149425287356,34.8
Thailand,TH,THA,🇹🇭,+66,2003,THB,59,1.38173302107728,42.7
Turkey,TR,TUR,🇹🇷,+90,2003,TRY,3750000,2.34301780693533,1600500
United States,US,USA,🇺🇸,+1,2003,USD,2.46,2.46,1
Venezuela,VE,VEN,🇻🇪,+58,2003,VEF,3700,2.3153942428035,1598
Argentina,AR,ARG,🇦🇷,+54,2004,ARS,4.36,1.47796610169492,2.95
Australia,AU,AUS,🇦🇺,+61,2004,AUD,3.25,2.27272727272727,1.43
Brazil,BR,BRA,🇧🇷,+55,2004,BRL,5.4,1.69811320754717,3.18
Britain,GB,GBR,🇬🇧,+44,2004,GBP,1.88,3.36519999925966,0.558659218
Canada,CA,CAN,🇨🇦,+1,2004,CAD,3.19,2.32846715328467,1.37
Chile,CL,CHL,🇨🇱,+56,2004,CLP,1400,2.18408736349454,641
China,CN,CHN,🇨🇳,+86,2004,CNY,10.4,1.256038647343,8.28
Colombia,CO,COL,🇨🇴,+57,2004,COP,6500,2.34671441929649,2769.83
Costa Rica,CR,CRI,🇨🇷,+506,2004,CRC,1130,2.60638911313574,433.55
Czech Republic,CZ,CZE,🇨🇿,+420,2004,CZK,56.42,2.12905660377359,26.5
Denmark,DK,DNK,🇩🇰,+45,2004,DKK,27.75,4.46141479099679,6.22
Egypt,EG,EGY,🇪🇬,+20,2004,EGP,10,1.61550888529887,6.19
Euro area,,EUZ,,,2004,EUR,2.74,3.2880000013152,0.833333333
Hong Kong,HK,HKG,🇭🇰,+852,2004,HKD,12,1.54043645699615,7.79
Hungary,HU,HUN,🇭🇺,+36,2004,HUF,530,2.51184834123223,211
Indonesia,ID,IDN,🇮🇩,+62,2004,IDR,16100,1.76535087719298,9120
Japan,JP,JPN,🇯🇵,+81,2004,JPY,262,2.31858407079646,113
Malaysia,MY,MYS,🇲🇾,+60,2004,MYR,5.04,1.32631578947368,3.8
Mexico,MX,MEX,🇲🇽,+52,2004,MXN,24,2.06896551724138,11.6
New Zealand,NZ,NZL,🇳🇿,+64,2004,NZD,4.35,2.65243902439024,1.64
Norway,NO,NOR,🇳🇴,+47,2004,NOK,35.5,5.18316275131039,6.8491
Pakistan,PK,PAK,🇵🇰,+92,2004,PKR,110,1.90443213296399,57.76
Peru,PE,PER,🇵🇪,+51,2004,PEN,8.99,2.57593123209169,3.49
Philippines,PH,PHL,🇵🇭,+63,2004,PHP,69,1.23434704830054,55.9
Poland,PL,POL,🇵🇱,+48,2004,PLN,6.3,1.62790697674419,3.87
Russia,RU,RUS,🇷🇺,+7,2004,RUB,42,1.44827586206897,29
Saudi Arabia,SA,SAU,🇸🇦,+966,2004,SAR,2.4,0.639965868487014,3.7502
Singapore,SG,SGP,🇸🇬,+65,2004,SGD,3.3,1.92982456140351,1.71
South Africa,ZA,ZAF,🇿🇦,+27,2004,ZAR,12.4,1.86186186186186,6.66
South Korea,KR,KOR,🇰🇷,+82,2004,KRW,3200,2.71877655055225,1177
Sri Lanka,LK,LKA,🇱🇰,+94,2004,LKR,140,1.41414141414141,99
Sweden,SE,SWE,🇸🇪,+46,2004,SEK,30,3.94218134034166,7.61
Switzerland,CH,CHE,🇨🇭,+41,2004,CHF,6.3,4.88372093023256,1.29
Taiwan,TW,TWN,🇹🇼,+886,2004,TWD,75,2.24550898203593,33.4
Thailand,TH,THA,🇹🇭,+66,2004,THB,59,1.44607843137255,40.8
Turkey,TR,TUR,🇹🇷,+90,2004,TRY,3950000,2.58001306335728,1531000
Ukraine,UA,UKR,🇺🇦,+380,2004,UAH,7.25,1.35984244584076,5.3315
United States,US,USA,🇺🇸,+1,2004,USD,2.47,2.47,1
Uruguay,UY,URY,🇺🇾,+598,2004,UYU,29.8,1.00218597612241,29.735
Venezuela,VE,VEN,🇻🇪,+58,2004,VEF,4400,1.4779979845482,2977
Argentina,AR,ARG,🇦🇷,+54,2005,ARS,4.75,1.6396272005523,2.897
Australia,AU,AUS,🇦🇺,+61,2005,AUD,3.25,2.49711870918171,1.3015
Brazil,BR,BRA,🇧🇷,+55,2005,BRL,5.9,2.39370334307043,2.4648
Britain,GB,GBR,🇬🇧,+44,2005,GBP,1.88,3.44040000271792,0.546448087
Canada,CA,CAN,🇨🇦,+1,2005,CAD,3.28,2.62526012485993,1.2494
Chile,CL,CHL,🇨🇱,+56,2005,CLP,1500,2.53185922862689,592.45
China,CN,CHN,🇨🇳,+86,2005,CNY,10.5,1.26866753661012,8.2764
Colombia,CO,COL,🇨🇴,+57,2005,COP,6500,2.79245607251794,2327.7
Costa Rica,CR,CRI,🇨🇷,+506,2005,CRC,1130,2.3774458236903,475.3
Czech Republic,CZ,CZE,🇨🇿,+420,2005,CZK,56.42,2.29901918837532,24.5409
Denmark,DK,DNK,🇩🇰,+45,2005,DKK,27.75,4.57565914224941,6.0647
Egypt,EG,EGY,🇪🇬,+20,2005,EGP,9,1.55172413793103,5.8
Euro area,,EUZ,,,2005,EUR,2.92,3.58313199823029,0.814929509
Hong Kong,HK,HKG,🇭🇰,+852,2005,HKD,12,1.54227768709756,7.7807
Hungary,HU,HUN,🇭🇺,+36,2005,HUF,530,2.6025937547571,203.643
Indonesia,ID,IDN,🇮🇩,+62,2005,IDR,14600,1.5295966474594,9545
Japan,JP,JPN,🇯🇵,+81,2005,JPY,250,2.34268846928735,106.715
Malaysia,MY,MYS,🇲🇾,+60,2005,MYR,5.25,1.38157894736842,3.8
Mexico,MX,MEX,🇲🇽,+52,2005,MXN,28,2.57648953301127,10.8675
New Zealand,NZ,NZL,🇳🇿,+64,2005,NZD,4.45,3.17335805462455,1.4023
Norway,NO,NOR,🇳🇴,+47,2005,NOK,39,6.06286726984423,6.4326
Pakistan,PK,PAK,🇵🇰,+92,2005,PKR,130,2.18047635021805,59.62
Peru,PE,PER,🇵🇪,+51,2005,PEN,8.99,2.76224420819763,3.2546
Philippines,PH,PHL,🇵🇭,+63,2005,PHP,80,1.46654445462878,54.55
Poland,PL,POL,🇵🇱,+48,2005,PLN,6.5,1.96398356296833,3.3096
Russia,RU,RUS,🇷🇺,+7,2005,RUB,42,1.47858689338332,28.4055
Saudi Arabia,SA,SAU,🇸🇦,+966,2005,SAR,9,2.39993600170662,3.7501
Singapore,SG,SGP,🇸🇬,+65,2005,SGD,3.6,2.16776058288673,1.6607
South Africa,ZA,ZAF,🇿🇦,+27,2005,ZAR,13.95,2.09831232514064,6.6482
South Korea,KR,KOR,🇰🇷,+82,2005,KRW,2500,2.48632521133764,1005.5
Sri Lanka,LK,LKA,🇱🇰,+94,2005,LKR,175,1.75096302966632,99.945
Sweden,SE,SWE,🇸🇪,+46,2005,SEK,31,4.16655466250907,7.4402
Switzerland,CH,CHE,🇨🇭,+41,2005,CHF,6.3,5.0501002004008,1.2475
Taiwan,TW,TWN,🇹🇼,+886,2005,TWD,75,2.40577385725742,31.175
Thailand,TH,THA,🇹🇭,+66,2005,THB,60,1.48038490007402,40.53
Turkey,TR,TUR,🇹🇷,+90,2005,TRY,4,2.9218407596786,1.369
UAE,AE,ARE,🇦🇪,+971,2005,AED,9,2.45064669843431,3.6725
Ukraine,UA,UKR,🇺🇦,+380,2005,UAH,7.25,1.43280632411067,5.06
United States,US,USA,🇺🇸,+1,2005,USD,2.58,2.58,1
Uruguay,UY,URY,🇺🇾,+598,2005,UYU,44,1.82383419689119,24.125
Venezuela,VE,VEN,🇻🇪,+58,2005,VEF,5600,2.12958526326998,2629.62
Argentina,AR,ARG,🇦🇷,+54,2006,ARS,7,2.29020121053493,3.0565
Australia,AU,AUS,🇦🇺,+61,2006,AUD,3.25,2.43896947911116,1.33253
Brazil,BR,BRA,🇧🇷,+55,2006,BRL,6.4,2.77717509221089,2.3045
Britain,GB,GBR,🇬🇧,+44,2006,GBP,1.94,3.64797600212458,0.531801744
Canada,CA,CAN,🇨🇦,+1,2006,CAD,3.52,3.1415948949083,1.12045
Chile,CL,CHL,🇨🇱,+56,2006,CLP,1560,2.94367393150297,529.95
China,CN,CHN,🇨🇳,+86,2006,CNY,10.5,1.30824819337154,8.026
Czech Republic,CZ,CZE,🇨🇿,+420,2006,CZK,59.05,2.67100299441826,22.1078
Denmark,DK,DNK,🇩🇰,+45,2006,DKK,27.75,4.76947535771065,5.81825
Egypt,EG,EGY,🇪🇬,+20,2006,EGP,9.5,1.64623315860157,5.77075
Euro area,,EUZ,,,2006,EUR,2.939573529,3.76691649704796,0.780365992
Hong Kong,HK,HKG,🇭🇰,+852,2006,HKD,12,1.54772806417912,7.7533
Hungary,HU,HUN,🇭🇺,+36,2006,HUF,560,2.71390936705818,206.3444
Indonesia,ID,IDN,🇮🇩,+62,2006,IDR,14600,1.5656836461126,9325
Japan,JP,JPN,🇯🇵,+81,2006,JPY,250,2.23005218322109,112.105
Malaysia,MY,MYS,🇲🇾,+60,2006,MYR,5.5,1.51536024245764,3.6295
Mexico,MX,MEX,🇲🇽,+52,2006,MXN,29,2.56566782564075,11.3031
New Zealand,NZ,NZL,🇳🇿,+64,2006,NZD,4.45,2.74520206537899,1.62101
Peru,PE,PER,🇵🇪,+51,2006,PEN,9.5,2.91321680466115,3.261
Philippines,PH,PHL,🇵🇭,+63,2006,PHP,85,1.61520190023753,52.625
Poland,PL,POL,🇵🇱,+48,2006,PLN,6.5,2.09734927318781,3.09915
Russia,RU,RUS,🇷🇺,+7,2006,RUB,48,1.77359013440488,27.06375
Singapore,SG,SGP,🇸🇬,+65,2006,SGD,3.6,2.26800226800227,1.5873
South Africa,ZA,ZAF,🇿🇦,+27,2006,ZAR,13.95,2.11459754433834,6.597
South Korea,KR,KOR,🇰🇷,+82,2006,KRW,2500,2.62494750104998,952.4
Sweden,SE,SWE,🇸🇪,+46,2006,SEK,33,4.53013569815569,7.28455
Switzerland,CH,CHE,🇨🇭,+41,2006,CHF,6.3,5.21156471026182,1.20885
Taiwan,TW,TWN,🇹🇼,+886,2006,TWD,75,2.3334681559379,32.141
Thailand,TH,THA,🇹🇭,+66,2006,THB,60,1.56067108856808,38.445
Turkey,TR,TUR,🇹🇷,+90,2006,TRY,4.2,2.72461887771651,1.5415
United States,US,USA,🇺🇸,+1,2006,USD,2.78,2.78,1
Venezuela,VE,VEN,🇻🇪,+58,2006,VEF,5701.25,2.26277792330468,2519.58
Colombia,CO,COL,🇨🇴,+57,2006,COP,6500,2.59626138360761,2503.6
Costa Rica,CR,CRI,🇨🇷,+506,2006,CRC,1130,2.21770830266812,509.535
Norway,NO,NOR,🇳🇴,+47,2006,NOK,43,7.04652344198089,6.1023
Pakistan,PK,PAK,🇵🇰,+92,2006,PKR,130,2.16468237449005,60.055
Saudi Arabia,SA,SAU,🇸🇦,+966,2006,SAR,9,2.40064017071219,3.749
Sri Lanka,LK,LKA,🇱🇰,+94,2006,LKR,190,1.84546646593172,102.955
UAE,AE,ARE,🇦🇪,+971,2006,AED,9,2.45057997059304,3.6726
Ukraine,UA,UKR,🇺🇦,+380,2006,UAH,8.5,1.68316831683168,5.05
Uruguay,UY,URY,🇺🇾,+598,2006,UYU,42.28,1.76718913270637,23.925
Argentina,AR,ARG,🇦🇷,+54,2007,ARS,8.25,2.66860747210092,3.0915
Australia,AU,AUS,🇦🇺,+61,2007,AUD,3.45,2.94871794871795,1.17
Brazil,BR,BRA,🇧🇷,+55,2007,BRL,6.9,3.60690015682175,1.913
Britain,GB,GBR,🇬🇧,+44,2007,GBP,1.99,4.00716350349906,0.496610632
Canada,CA,CAN,🇨🇦,+1,2007,CAD,3.88,3.68313636147895,1.05345
Chile,CL,CHL,🇨🇱,+56,2007,CLP,1565,2.97104888467015,526.75
China,CN,CHN,🇨🇳,+86,2007,CNY,11,1.44647389114626,7.6047
Colombia,CO,COL,🇨🇴,+57,2007,COP,6900,3.52760736196319,1956
Costa Rica,CR,CRI,🇨🇷,+506,2007,CRC,1130,2.17883827428296,518.625
Czech Republic,CZ,CZE,🇨🇿,+420,2007,CZK,52.9,2.50512034285579,21.11675
Denmark,DK,DNK,🇩🇰,+45,2007,DKK,27.75,5.08213834404703,5.4603
Egypt,EG,EGY,🇪🇬,+20,2007,EGP,9.54,1.67655199683669,5.69025
Euro area,,EUZ,,,2007,EUR,3.057482443,4.1668899487422,0.733756466
Hong Kong,HK,HKG,🇭🇰,+852,2007,HKD,12,1.53540057961372,7.81555
Hungary,HU,HUN,🇭🇺,+36,2007,HUF,600,3.32943789100086,180.2106
Indonesia,ID,IDN,🇮🇩,+62,2007,IDR,15900,1.76372712146423,9015
Japan,JP,JPN,🇯🇵,+81,2007,JPY,280,2.2891714017087,122.315
Malaysia,MY,MYS,🇲🇾,+60,2007,MYR,5.5,1.60139758334547,3.4345
Mexico,MX,MEX,🇲🇽,+52,2007,MXN,29,2.693277486522,10.76755
New Zealand,NZ,NZL,🇳🇿,+64,2007,NZD,4.6,3.59459248261311,1.2797
Norway,NO,NOR,🇳🇴,+47,2007,NOK,40,6.88006329658233,5.8139
Pakistan,PK,PAK,🇵🇰,+92,2007,PKR,140,2.31903263210204,60.37
Peru,PE,PER,🇵🇪,+51,2007,PEN,9.5,2.99968424376381,3.167
Philippines,PH,PHL,🇵🇭,+63,2007,PHP,85,1.85195272073642,45.8975
Poland,PL,POL,🇵🇱,+48,2007,PLN,6.9,2.50740410996239,2.75185
Russia,RU,RUS,🇷🇺,+7,2007,RUB,52,2.0274287719435,25.64825
Saudi Arabia,SA,SAU,🇸🇦,+966,2007,SAR,9,2.39971203455585,3.75045
Singapore,SG,SGP,🇸🇬,+65,2007,SGD,3.95,2.59228876127974,1.52375
South Africa,ZA,ZAF,🇿🇦,+27,2007,ZAR,15.5,2.22349734614833,6.971
South Korea,KR,KOR,🇰🇷,+82,2007,KRW,2900,3.14363143631436,922.5
Sri Lanka,LK,LKA,🇱🇰,+94,2007,LKR,210,1.8850987432675,111.4
Sweden,SE,SWE,🇸🇪,+46,2007,SEK,33,4.85890762922118,6.79165
Switzerland,CH,CHE,🇨🇭,+41,2007,CHF,6.3,5.20145310435931,1.2112
Taiwan,TW,TWN,🇹🇼,+886,2007,TWD,75,2.28864374971392,32.7705
Thailand,TH,THA,🇹🇭,+66,2007,THB,62,1.79944855608765,34.455
Turkey,TR,TUR,🇹🇷,+90,2007,TRY,4.75,3.6591942069178,1.2981
UAE,AE,ARE,🇦🇪,+971,2007,AED,10,2.72268129654083,3.67285
Ukraine,UA,UKR,🇺🇦,+380,2007,UAH,9.25,1.83988065638986,5.0275
United States,US,USA,🇺🇸,+1,2007,USD,3,3,1
Uruguay,UY,URY,🇺🇾,+598,2007,UYU,62,2.5892670703696,23.945
Venezuela,VE,VEN,🇻🇪,+58,2007,VEF,7400,3.44618823638989,2147.3
Argentina,AR,ARG,🇦🇷,+54,2008,ARS,11,3.64298724954463,3.0195
Australia,AU,AUS,🇦🇺,+61,2008,AUD,3.45,3.36257309941521,1.026
Brazil,BR,BRA,🇧🇷,+55,2008,BRL,7.5,4.73305566073457,1.5846
Britain,GB,GBR,🇬🇧,+44,2008,GBP,2.29,4.57049650263512,0.501039657
Canada,CA,CAN,🇨🇦,+1,2008,CAD,4.09,4.07553186189029,1.00355
Chile,CL,CHL,🇨🇱,+56,2008,CLP,1550,3.13352875770747,494.65
China,CN,CHN,🇨🇳,+86,2008,CNY,12.5,1.82908984489318,6.834
Colombia,CO,COL,🇨🇴,+57,2008,COP,7000,3.89180774469741,1798.65
Costa Rica,CR,CRI,🇨🇷,+506,2008,CRC,1800,3.26666908642155,551.02
Czech Republic,CZ,CZE,🇨🇿,+420,2008,CZK,66.08,4.55788577005715,14.49795
Denmark,DK,DNK,🇩🇰,+45,2008,DKK,28,5.95187484057478,4.7044
Egypt,EG,EGY,🇪🇬,+20,2008,EGP,13,2.44705882352941,5.3125
Euro area,,EUZ,,,2008,EUR,3.36856,5.34270458800347,0.630497147
Hong Kong,HK,HKG,🇭🇰,+852,2008,HKD,13.3,1.70569677071844,7.7974
Hungary,HU,HUN,🇭🇺,+36,2008,HUF,670,4.64446289905988,144.2578
Indonesia,ID,IDN,🇮🇩,+62,2008,IDR,18700,2.04326923076923,9152
Japan,JP,JPN,🇯🇵,+81,2008,JPY,280,2.62037340320996,106.855
Malaysia,MY,MYS,🇲🇾,+60,2008,MYR,5.5,1.69857936998147,3.238
Mexico,MX,MEX,🇲🇽,+52,2008,MXN,32,3.14697349658258,10.1685
New Zealand,NZ,NZL,🇳🇿,+64,2008,NZD,4.9,3.7186005919405,1.3177
Norway,NO,NOR,🇳🇴,+47,2008,NOK,40,7.87533347115167,5.07915
Pakistan,PK,PAK,🇵🇰,+92,2008,PKR,140,1.97461212976023,70.9
Peru,PE,PER,🇵🇪,+51,2008,PEN,9.5,3.34507042253521,2.84
Philippines,PH,PHL,🇵🇭,+63,2008,PHP,87,1.95571540968866,44.485
Poland,PL,POL,🇵🇱,+48,2008,PLN,7,3.44581456595043,2.03145
Russia,RU,RUS,🇷🇺,+7,2008,RUB,59,2.53942565938985,23.2336
Saudi Arabia,SA,SAU,🇸🇦,+966,2008,SAR,10,2.66698670507128,3.74955
Singapore,SG,SGP,🇸🇬,+65,2008,SGD,3.95,2.91997782295324,1.35275
South Africa,ZA,ZAF,🇿🇦,+27,2008,ZAR,16.95,2.24110005619277,7.56325
South Korea,KR,KOR,🇰🇷,+82,2008,KRW,3200,3.1432640832965,1018.05
Sri Lanka,LK,LKA,🇱🇰,+94,2008,LKR,210,1.95258019525802,107.55
Sweden,SE,SWE,🇸🇪,+46,2008,SEK,38,6.37492974995177,5.96085
Switzerland,CH,CHE,🇨🇭,+41,2008,CHF,6.5,6.35914493958812,1.02215
Taiwan,TW,TWN,🇹🇼,+886,2008,TWD,75,2.47015232606011,30.3625
Thailand,TH,THA,🇹🇭,+66,2008,THB,62,1.85851318944844,33.36
Turkey,TR,TUR,🇹🇷,+90,2008,TRY,5.15,4.31775309159505,1.19275
UAE,AE,ARE,🇦🇪,+971,2008,AED,10,2.72268129654083,3.67285
Ukraine,UA,UKR,🇺🇦,+380,2008,UAH,11,2.39130434782609,4.6
United States,US,USA,🇺🇸,+1,2008,USD,3.21,3.21,1
Uruguay,UY,URY,🇺🇾,+598,2008,UYU,61,3.18537859007833,19.15
Argentina,AR,ARG,🇦🇷,+54,2009,ARS,11.5,3.01738274844211,3.81125
Australia,AU,AUS,🇦🇺,+61,2009,AUD,4.34,3.37244541145388,1.2869
Brazil,BR,BRA,🇧🇷,+55,2009,BRL,8.03,4.0208302037955,1.9971
Britain,GB,GBR,🇬🇧,+44,2009,GBP,2.29,3.68827400060635,0.620886626
Canada,CA,CAN,🇨🇦,+1,2009,CAD,3.89,3.35099280699487,1.16085
Chile,CL,CHL,🇨🇱,+56,2009,CLP,1750,3.19022878497858,548.55
China,CN,CHN,🇨🇳,+86,2009,CNY,12.5,1.82943785033735,6.8327
Colombia,CO,COL,🇨🇴,+57,2009,COP,7000,3.34033212445123,2095.6
Costa Rica,CR,CRI,🇨🇷,+506,2009,CRC,2000,3.43306383782206,582.57
Czech Republic,CZ,CZE,🇨🇿,+420,2009,CZK,67.92,3.63956906044782,18.66155
Denmark,DK,DNK,🇩🇰,+45,2009,DKK,29.5,5.52693208430913,5.3375
Egypt,EG,EGY,🇪🇬,+20,2009,EGP,13,2.32776758135995,5.58475
Euro area,,EUZ,,,2009,EUR,3.31,4.6171189975617,0.716897269
Hong Kong,HK,HKG,🇭🇰,+852,2009,HKD,13.3,1.71601832139862,7.7505
Hungary,HU,HUN,🇭🇺,+36,2009,HUF,720,3.61522552228711,199.1577
Indonesia,ID,IDN,🇮🇩,+62,2009,IDR,20900,2.04901960784314,10200
Israel,IL,ISR,🇮🇱,+972,2009,ILS,15,3.77429703717683,3.97425
Japan,JP,JPN,🇯🇵,+81,2009,JPY,320,3.45703019499811,92.565
Malaysia,MY,MYS,🇲🇾,+60,2009,MYR,6.77,1.87951138256524,3.602
Mexico,MX,MEX,🇲🇽,+52,2009,MXN,33,2.38950356253258,13.8104
New Zealand,NZ,NZL,🇳🇿,+64,2009,NZD,4.9,3.07721292429428,1.59235
Norway,NO,NOR,🇳🇴,+47,2009,NOK,40,6.14510120213542,6.50925
Pakistan,PK,PAK,🇵🇰,+92,2009,PKR,190,2.30024213075061,82.6
Peru,PE,PER,🇵🇪,+51,2009,PEN,8.056,2.66102926603686,3.0274
Philippines,PH,PHL,🇵🇭,+63,2009,PHP,99.39,2.0541490131239,48.385
Poland,PL,POL,🇵🇱,+48,2009,PLN,7.6,2.40868393946597,3.15525
Russia,RU,RUS,🇷🇺,+7,2009,RUB,67,2.04293829411603,32.7959
Saudi Arabia,SA,SAU,🇸🇦,+966,2009,SAR,11,2.9330204778157,3.7504
Singapore,SG,SGP,🇸🇬,+65,2009,SGD,4.22,2.88152953226357,1.4645
South Africa,ZA,ZAF,🇿🇦,+27,2009,ZAR,17.95,2.16774349375038,8.2805
South Korea,KR,KOR,🇰🇷,+82,2009,KRW,3400,2.58653480410803,1314.5
Sri Lanka,LK,LKA,🇱🇰,+94,2009,LKR,210,1.82831272853909,114.86
Sweden,SE,SWE,🇸🇪,+46,2009,SEK,39,4.93099764197164,7.90915
Switzerland,CH,CHE,🇨🇭,+41,2009,CHF,6.5,5.98499148289674,1.08605
Taiwan,TW,TWN,🇹🇼,+886,2009,TWD,75,2.2607044355021,33.1755
Thailand,TH,THA,🇹🇭,+66,2009,THB,64.49,1.88760427337919,34.165
Turkey,TR,TUR,🇹🇷,+90,2009,TRY,5.65,3.64504370826748,1.55005
UAE,AE,ARE,🇦🇪,+971,2009,AED,10,2.72257010618023,3.673
Ukraine,UA,UKR,🇺🇦,+380,2009,UAH,14,1.82767624020888,7.66
United States,US,USA,🇺🇸,+1,2009,USD,3.43,3.43,1
Uruguay,UY,URY,🇺🇾,+598,2009,UYU,61,2.63271471730686,23.17
Argentina,AR,ARG,🇦🇷,+54,2010,ARS,14,3.55894502700985,3.93375
Australia,AU,AUS,🇦🇺,+61,2010,AUD,4.35,3.83691750028815,1.133722578
Brazil,BR,BRA,🇧🇷,+55,2010,BRL,8.71,4.9071804839573,1.77495
Britain,GB,GBR,🇬🇧,+44,2010,GBP,2.29,3.48354799990525,0.657375756
Canada,CA,CAN,🇨🇦,+1,2010,CAD,4.17,4.00057562239171,1.04235
Chile,CL,CHL,🇨🇱,+56,2010,CLP,1750,3.34192685954359,523.65
China,CN,CHN,🇨🇳,+86,2010,CNY,13.2,1.9477932387965,6.7769
Colombia,CO,COL,🇨🇴,+57,2010,COP,8200,4.38866440097407,1868.45
Costa Rica,CR,CRI,🇨🇷,+506,2010,CRC,2000,3.83057372417954,522.115
Czech Republic,CZ,CZE,🇨🇿,+420,2010,CZK,67.64,3.42522635662055,19.7476
Denmark,DK,DNK,🇩🇰,+45,2010,DKK,28.5,4.90225589775786,5.81365
Egypt,EG,EGY,🇪🇬,+20,2010,EGP,13,2.28110194771012,5.699
Euro area,,EUZ,,,2010,EUR,3.380031071,4.33252382664316,0.78015291
Hong Kong,HK,HKG,🇭🇰,+852,2010,HKD,14.8,1.90373285997273,7.7742
Hungary,HU,HUN,🇭🇺,+36,2010,HUF,740,3.33379661092544,221.96915
Indonesia,ID,IDN,🇮🇩,+62,2010,IDR,22780,2.51351649564162,9063
Israel,IL,ISR,🇮🇱,+972,2010,ILS,14.9,3.85820451073306,3.8619
Japan,JP,JPN,🇯🇵,+81,2010,JPY,320,3.67056664372563,87.18
Malaysia,MY,MYS,🇲🇾,+60,2010,MYR,7.05,2.19386961257196,3.2135
Mexico,MX,MEX,🇲🇽,+52,2010,MXN,32,2.50352057580973,12.782
New Zealand,NZ,NZL,🇳🇿,+64,2010,NZD,5,3.5900000004308,1.39275766
Norway,NO,NOR,🇳🇴,+47,2010,NOK,45,7.20432259355613,6.24625
Pakistan,PK,PAK,🇵🇰,+92,2010,PKR,210,2.45542239111371,85.525
Peru,PE,PER,🇵🇪,+51,2010,PEN,10,3.53669319186561,2.8275
Philippines,PH,PHL,🇵🇭,+63,2010,PHP,102,2.19496449322143,46.47
Poland,PL,POL,🇵🇱,+48,2010,PLN,8.3,2.59626513184648,3.1969
Russia,RU,RUS,🇷🇺,+7,2010,RUB,71,2.33220828919383,30.44325
Saudi Arabia,SA,SAU,🇸🇦,+966,2010,SAR,10,2.66634670506206,3.75045
Singapore,SG,SGP,🇸🇬,+65,2010,SGD,4.23,3.07815456265464,1.3742
South Africa,ZA,ZAF,🇿🇦,+27,2010,ZAR,18.45,2.44808598155643,7.5365
South Korea,KR,KOR,🇰🇷,+82,2010,KRW,3400,2.82286520818631,1204.45
Sri Lanka,LK,LKA,🇱🇰,+94,2010,LKR,210,1.86211483041454,112.775
Sweden,SE,SWE,🇸🇪,+46,2010,SEK,48.4,6.56400241403395,7.37355
Switzerland,CH,CHE,🇨🇭,+41,2010,CHF,6.5,6.18546890612361,1.05085
Taiwan,TW,TWN,🇹🇼,+886,2010,TWD,75,2.336048340627,32.1055
Thailand,TH,THA,🇹🇭,+66,2010,THB,70,2.16684723726977,32.305
Turkey,TR,TUR,🇹🇷,+90,2010,TRY,5.95,3.89066893349899,1.5293
UAE,AE,ARE,🇦🇪,+971,2010,AED,11,2.99494942619492,3.67285
Ukraine,UA,UKR,🇺🇦,+380,2010,UAH,14.5,1.83567540194961,7.899
United States,US,USA,🇺🇸,+1,2010,USD,3.53,3.53,1
Uruguay,UY,URY,🇺🇾,+598,2010,UYU,79,3.74407582938389,21.1
Argentina,AR,ARG,🇦🇷,+54,2011,ARS,20,4.83968542044767,4.1325
Australia,AU,AUS,🇦🇺,+61,2011,AUD,4.56,4.94372400141217,0.922381589
Brazil,BR,BRA,🇧🇷,+55,2011,BRL,9.5,6.16242864556305,1.5416
Britain,GB,GBR,🇬🇧,+44,2011,GBP,2.39,3.89163699974627,0.614137444
Canada,CA,CAN,🇨🇦,+1,2011,CAD,4.73,5.00079293756938,0.94585
Chile,CL,CHL,🇨🇱,+56,2011,CLP,1850,3.99783900594273,462.75
China,CN,CHN,🇨🇳,+86,2011,CNY,14.65,2.27307990690458,6.445
Colombia,CO,COL,🇨🇴,+57,2011,COP,8400,4.74442247952556,1770.5
Costa Rica,CR,CRI,🇨🇷,+506,2011,CRC,2050,4.06500034701222,504.305
Czech Republic,CZ,CZE,🇨🇿,+420,2011,CZK,69.32,4.07240084831893,17.0219
Denmark,DK,DNK,🇩🇰,+45,2011,DKK,28.5,5.48240340870837,5.19845
Egypt,EG,EGY,🇪🇬,+20,2011,EGP,14.09,2.36330090573633,5.962
Euro area,,EUZ,,,2011,EUR,3.437660401,4.92840183586624,0.697520315
Hong Kong,HK,HKG,🇭🇰,+852,2011,HKD,15.1,1.93793475191868,7.7918
Hungary,HU,HUN,🇭🇺,+36,2011,HUF,760,4.04099697775963,188.0724
India,IN,IND,🇮🇳,+91,2011,INR,84,1.89189189189189,44.4
Indonesia,ID,IDN,🇮🇩,+62,2011,IDR,22534,2.64390472838203,8523
Israel,IL,ISR,🇮🇱,+972,2011,ILS,15.9,4.67186742474327,3.40335
Japan,JP,JPN,🇯🇵,+81,2011,JPY,320,4.08345562432208,78.365
Malaysia,MY,MYS,🇲🇾,+60,2011,MYR,7.2,2.42179616548941,2.973
Mexico,MX,MEX,🇲🇽,+52,2011,MXN,32,2.74189747873958,11.67075
New Zealand,NZ,NZL,🇳🇿,+64,2011,NZD,5.1,4.41150000061761,1.156069364
Norway,NO,NOR,🇳🇴,+47,2011,NOK,45,8.31170750177778,5.41405
Pakistan,PK,PAK,🇵🇰,+92,2011,PKR,205.13,2.37570212519544,86.345
Peru,PE,PER,🇵🇪,+51,2011,PEN,10,3.65296803652968,2.7375
Philippines,PH,PHL,🇵🇭,+63,2011,PHP,118,2.78236265031832,42.41
Poland,PL,POL,🇵🇱,+48,2011,PLN,8.63,3.08566933638444,2.7968
Russia,RU,RUS,🇷🇺,+7,2011,RUB,75,2.70245923790649,27.7525
Saudi Arabia,SA,SAU,🇸🇦,+966,2011,SAR,10,2.66652445202923,3.7502
Singapore,SG,SGP,🇸🇬,+65,2011,SGD,4.41,3.65353547906052,1.20705
South Africa,ZA,ZAF,🇿🇦,+27,2011,ZAR,19.45,2.87345708650657,6.76885
South Korea,KR,KOR,🇰🇷,+82,2011,KRW,3700,3.50312440825601,1056.2
Sri Lanka,LK,LKA,🇱🇰,+94,2011,LKR,280,2.55766156656771,109.475
Sweden,SE,SWE,🇸🇪,+46,2011,SEK,48.4,7.63985351685819,6.3352
Switzerland,CH,CHE,🇨🇭,+41,2011,CHF,6.5,8.06301556782237,0.80615
Taiwan,TW,TWN,🇹🇼,+886,2011,TWD,75,2.60109592841784,28.834
Thailand,TH,THA,🇹🇭,+66,2011,THB,70,2.35294117647059,29.75
Turkey,TR,TUR,🇹🇷,+90,2011,TRY,6.5,3.77281829526656,1.72285
UAE,AE,ARE,🇦🇪,+971,2011,AED,12,3.2670396536938,3.67305
Ukraine,UA,UKR,🇺🇦,+380,2011,UAH,16.5,2.06404803602702,7.994
United States,US,USA,🇺🇸,+1,2011,USD,3.64,3.64,1
Uruguay,UY,URY,🇺🇾,+598,2011,UYU,90,4.87804878048781,18.45
Venezuela,VE,VEN,🇻🇪,+58,2011,VEF,28,6.51973967610865,4.29465
Argentina,AR,ARG,🇦🇷,+54,2012,ARS,19,4.16096359156857,4.56625
Australia,AU,AUS,🇦🇺,+61,2012,AUD,4.56,4.68015599908292,0.974326497
Brazil,BR,BRA,🇧🇷,+55,2012,BRL,10.08,4.93597434076831,2.04215
Britain,GB,GBR,🇬🇧,+44,2012,GBP,2.69,4.1623715002999,0.646266197
Canada,CA,CAN,🇨🇦,+1,2012,CAD,5.12,5.02231595468145,1.01945
Chile,CL,CHL,🇨🇱,+56,2012,CLP,2050,4.15779332724876,493.05
China,CN,CHN,🇨🇳,+86,2012,CNY,15.65,2.44971433043751,6.3885
Colombia,CO,COL,🇨🇴,+57,2012,COP,8600,4.76592914836725,1804.475
Costa Rica,CR,CRI,🇨🇷,+506,2012,CRC,2050,4.02323664481689,509.54
Czech Republic,CZ,CZE,🇨🇿,+420,2012,CZK,70.33,3.34141011022425,21.048
Denmark,DK,DNK,🇩🇰,+45,2012,DKK,28.5,4.64502249168785,6.1356
Egypt,EG,EGY,🇪🇬,+20,2012,EGP,16,2.63743509437072,6.0665
Euro area,,EUZ,,,2012,EUR,3.583482241,4.34443469296111,0.824844311
Hong Kong,HK,HKG,🇭🇰,+852,2012,HKD,16.5,2.126795692272,7.75815
Hungary,HU,HUN,🇭🇺,+36,2012,HUF,830,3.48424596089836,238.2151
India,IN,IND,🇮🇳,+91,2012,INR,89,1.58461675420636,56.165
Indonesia,ID,IDN,🇮🇩,+62,2012,IDR,24200,2.55206960189823,9482.5
Israel,IL,ISR,🇮🇱,+972,2012,ILS,11.9,2.91934989267096,4.07625
Japan,JP,JPN,🇯🇵,+81,2012,JPY,320,4.09102531321913,78.22
Malaysia,MY,MYS,🇲🇾,+60,2012,MYR,7.4,2.33181030408067,3.1735
Mexico,MX,MEX,🇲🇽,+52,2012,MXN,37,2.70329509753781,13.687
New Zealand,NZ,NZL,🇳🇿,+64,2012,NZD,5.1,4.00452000065995,1.273560876
Norway,NO,NOR,🇳🇴,+47,2012,NOK,43,7.05837936326852,6.09205
Pakistan,PK,PAK,🇵🇰,+92,2012,PKR,285,3.01252576502299,94.605
Peru,PE,PER,🇵🇪,+51,2012,PEN,10,3.71402042711235,2.6925
Philippines,PH,PHL,🇵🇭,+63,2012,PHP,118,2.79620853080569,42.2
Poland,PL,POL,🇵🇱,+48,2012,PLN,9.1,2.62979178406809,3.46035
Russia,RU,RUS,🇷🇺,+7,2012,RUB,75,2.28901046077781,32.76525
Saudi Arabia,SA,SAU,🇸🇦,+966,2012,SAR,10,2.66648890073995,3.75025
Singapore,SG,SGP,🇸🇬,+65,2012,SGD,4.4,3.49622566547477,1.2585
South Africa,ZA,ZAF,🇿🇦,+27,2012,ZAR,19.95,2.35584473861342,8.4683
South Korea,KR,KOR,🇰🇷,+82,2012,KRW,3700,3.21459600347524,1151
Sri Lanka,LK,LKA,🇱🇰,+94,2012,LKR,290,2.21374045801527,131
Sweden,SE,SWE,🇸🇪,+46,2012,SEK,39.97301987,5.72868135202144,6.9777
Switzerland,CH,CHE,🇨🇭,+41,2012,CHF,6.5,6.56167979002625,0.9906
Taiwan,TW,TWN,🇹🇼,+886,2012,TWD,75,2.48381381331655,30.1955
Thailand,TH,THA,🇹🇭,+66,2012,THB,82,2.58675078864353,31.7
Turkey,TR,TUR,🇹🇷,+90,2012,TRY,8.25,4.51584651595599,1.8269
UAE,AE,ARE,🇦🇪,+971,2012,AED,12,3.26712860234961,3.67295
Ukraine,UA,UKR,🇺🇦,+380,2012,UAH,15,1.85517284026962,8.0855
United States,US,USA,🇺🇸,+1,2012,USD,3.96,3.96,1
Uruguay,UY,URY,🇺🇾,+598,2012,UYU,99,4.52674897119342,21.87
Venezuela,VE,VEN,🇻🇪,+58,2012,VEF,34,7.9168267495605,4.29465
Argentina,AR,ARG,🇦🇷,+54,2013,ARS,21,3.87990762124711,5.4125
Australia,AU,AUS,🇦🇺,+61,2013,AUD,5.035,4.61986425117506,1.089858863
Brazil,BR,BRA,🇧🇷,+55,2013,BRL,12,5.28483033492612,2.27065
Britain,GB,GBR,🇬🇧,+44,2013,GBP,2.69,4.02007049936362,0.669142494
Canada,CA,CAN,🇨🇦,+1,2013,CAD,5.53,5.26040428061831,1.05125
Chile,CL,CHL,🇨🇱,+56,2013,CLP,2000,3.93580698802531,508.155
China,CN,CHN,🇨🇳,+86,2013,CNY,16,2.60836960597317,6.1341
Colombia,CO,COL,🇨🇴,+57,2013,COP,8600,4.47683498178032,1921
Costa Rica,CR,CRI,🇨🇷,+506,2013,CRC,2150,4.31142527698401,498.675
Czech Republic,CZ,CZE,🇨🇿,+420,2013,CZK,70.45,3.49059595298967,20.1828
Denmark,DK,DNK,🇩🇰,+45,2013,DKK,28.5,4.91328483260352,5.8006
Egypt,EG,EGY,🇪🇬,+20,2013,EGP,16.75,2.38973341988686,7.00915
Euro area,,EUZ,,,2013,EUR,3.623870489,4.65939148386547,0.777756173
Hong Kong,HK,HKG,🇭🇰,+852,2013,HKD,17,2.19166779473097,7.75665
Hungary,HU,HUN,🇭🇺,+36,2013,HUF,860,3.76430215965894,228.462
India,IN,IND,🇮🇳,+91,2013,INR,90,1.50050016672224,59.98
Indonesia,ID,IDN,🇮🇩,+62,2013,IDR,27939,2.80371299548419,9965
Israel,IL,ISR,🇮🇱,+972,2013,ILS,17.5,4.79984640491504,3.64595
Japan,JP,JPN,🇯🇵,+81,2013,JPY,320,3.19664352429949,100.105
Malaysia,MY,MYS,🇲🇾,+60,2013,MYR,7.3,2.29523659801918,3.1805
Mexico,MX,MEX,🇲🇽,+52,2013,MXN,37,2.85909675724337,12.94115
New Zealand,NZ,NZL,🇳🇿,+64,2013,NZD,5.5,4.29907500125211,1.279344975
Norway,NO,NOR,🇳🇴,+47,2013,NOK,46,7.50640492159071,6.1281
Pakistan,PK,PAK,🇵🇰,+92,2013,PKR,300,2.99895036737142,100.035
Peru,PE,PER,🇵🇪,+51,2013,PEN,10,3.59324469996407,2.783
Philippines,PH,PHL,🇵🇭,+63,2013,PHP,115.21,2.65216390423573,43.44
Poland,PL,POL,🇵🇱,+48,2013,PLN,9.2,2.73362056158075,3.3655
Russia,RU,RUS,🇷🇺,+7,2013,RUB,87,2.64125395808603,32.9389
Saudi Arabia,SA,SAU,🇸🇦,+966,2013,SAR,10,2.66652445202923,3.7502
Singapore,SG,SGP,🇸🇬,+65,2013,SGD,4.7,3.68714207264454,1.2747
South Africa,ZA,ZAF,🇿🇦,+27,2013,ZAR,22.5,2.23941874642315,10.04725
South Korea,KR,KOR,🇰🇷,+82,2013,KRW,3900,3.43400545918817,1135.7
Sri Lanka,LK,LKA,🇱🇰,+94,2013,LKR,370,2.82874617737003,130.8
Sweden,SE,SWE,🇸🇪,+46,2013,SEK,41.61,6.15687377003092,6.7583
Switzerland,CH,CHE,🇨🇭,+41,2013,CHF,6.5,6.7190407277238,0.9674
Taiwan,TW,TWN,🇹🇼,+886,2013,TWD,79,2.63083404099439,30.0285
Thailand,TH,THA,🇹🇭,+66,2013,THB,89,2.84572342126299,31.275
Turkey,TR,TUR,🇹🇷,+90,2013,TRY,8.5,4.34238422437355,1.95745
UAE,AE,ARE,🇦🇪,+971,2013,AED,12,3.26708412741628,3.673
Ukraine,UA,UKR,🇺🇦,+380,2013,UAH,19,2.3298589822195,8.155
United States,US,USA,🇺🇸,+1,2013,USD,4.18,4.18,1
Uruguay,UY,URY,🇺🇾,+598,2013,UYU,105,4.97984349063315,21.085
Venezuela,VE,VEN,🇻🇪,+58,2013,VEF,45,7.15182530474722,6.2921
Argentina,AR,ARG,🇦🇷,+54,2014,ARS,21,2.5707727620505,8.16875
Australia,AU,AUS,🇦🇺,+61,2014,AUD,5.1,4.81414500013119,1.059378145
Brazil,BR,BRA,🇧🇷,+55,2014,BRL,13,5.85519648688211,2.22025
Britain,GB,GBR,🇬🇧,+44,2014,GBP,2.89,4.92513799618893,0.586785589
Canada,CA,CAN,🇨🇦,+1,2014,CAD,5.64,5.25115218099716,1.07405
Chile,CL,CHL,🇨🇱,+56,2014,CLP,2100,3.72248023540256,564.14
China,CN,CHN,🇨🇳,+86,2014,CNY,16.9,2.72655405514415,6.1983
Colombia,CO,COL,🇨🇴,+57,2014,COP,8600,4.65456119936135,1847.65
Costa Rica,CR,CRI,🇨🇷,+506,2014,CRC,2150,4.00148892611204,537.3
Czech Republic,CZ,CZE,🇨🇿,+420,2014,CZK,70.45,3.45551484473482,20.3877
Denmark,DK,DNK,🇩🇰,+45,2014,DKK,28.5,5.14551888495703,5.5388
Egypt,EG,EGY,🇪🇬,+20,2014,EGP,16.93,2.36781560968105,7.15005
Euro area,,EUZ,,,2014,EUR,3.67923829,4.95354246993891,0.742748914
Hong Kong,HK,HKG,🇭🇰,+852,2014,HKD,18.8,2.42566560651317,7.75045
Hungary,HU,HUN,🇭🇺,+36,2014,HUF,860,3.76687180787727,228.30615
India,IN,IND,🇮🇳,+91,2014,INR,105,1.74741528156269,60.08875
Indonesia,ID,IDN,🇮🇩,+62,2014,IDR,27939,2.42842242503259,11505
Israel,IL,ISR,🇮🇱,+972,2014,ILS,17.5,5.12625227019743,3.4138
Japan,JP,JPN,🇯🇵,+81,2014,JPY,370,3.6442430808628,101.53
Malaysia,MY,MYS,🇲🇾,+60,2014,MYR,7.63,2.40883977900553,3.1675
Mexico,MX,MEX,🇲🇽,+52,2014,MXN,42,3.24761937900878,12.93255
New Zealand,NZ,NZL,🇳🇿,+64,2014,NZD,5.7,4.94475000069227,1.152737752
Norway,NO,NOR,🇳🇴,+47,2014,NOK,48,7.75782651560455,6.1873
Pakistan,PK,PAK,🇵🇰,+92,2014,PKR,300,3.04012971220105,98.68
Peru,PE,PER,🇵🇪,+51,2014,PEN,10,3.58680057388809,2.788
Philippines,PH,PHL,🇵🇭,+63,2014,PHP,160,3.70327508390233,43.205
Poland,PL,POL,🇵🇱,+48,2014,PLN,9.2,2.99537670117862,3.0714
Russia,RU,RUS,🇷🇺,+7,2014,RUB,89,2.55473666836504,34.83725
Saudi Arabia,SA,SAU,🇸🇦,+966,2014,SAR,11,2.93305958110576,3.75035
Singapore,SG,SGP,🇸🇬,+65,2014,SGD,4.7,3.79644588045234,1.238
South Africa,ZA,ZAF,🇿🇦,+27,2014,ZAR,24.5,2.33011555471016,10.5145
South Korea,KR,KOR,🇰🇷,+82,2014,KRW,4100,4.00488400488401,1023.75
Sri Lanka,LK,LKA,🇱🇰,+94,2014,LKR,350,2.6870369659514,130.255
Sweden,SE,SWE,🇸🇪,+46,2014,SEK,40.7,5.95377413692218,6.836
Switzerland,CH,CHE,🇨🇭,+41,2014,CHF,6.16,6.82624113475177,0.9024
Taiwan,TW,TWN,🇹🇼,+886,2014,TWD,79,2.63491428190248,29.982
Thailand,TH,THA,🇹🇭,+66,2014,THB,99,3.11516677155444,31.78
Turkey,TR,TUR,🇹🇷,+90,2014,TRY,9.25,4.42319187089062,2.09125
UAE,AE,ARE,🇦🇪,+971,2014,AED,13,3.53929295816828,3.67305
Ukraine,UA,UKR,🇺🇦,+380,2014,UAH,19,1.6260162601626,11.685
United States,US,USA,🇺🇸,+1,2014,USD,4.29,4.29,1
Uruguay,UY,URY,🇺🇾,+598,2014,UYU,113,4.91946016543317,22.97
Venezuela,VE,VEN,🇻🇪,+58,2014,VEF,75,6.81818181818182,11
Vietnam,VN,VNM,🇻🇳,+84,2014,VND,60000,2.82618935468676,21230
Argentina,AR,ARG,🇦🇷,+54,2015,ARS,28,3.06513409961686,9.135
Australia,AU,AUS,🇦🇺,+61,2015,AUD,5.3,3.92226499999019,1.35126005
Brazil,BR,BRA,🇧🇷,+55,2015,BRL,13.5,4.28251939029613,3.15235
Britain,GB,GBR,🇬🇧,+44,2015,GBP,2.89,4.51244600294302,0.640450877
Canada,CA,CAN,🇨🇦,+1,2015,CAD,5.85,4.53593859036985,1.2897
Chile,CL,CHL,🇨🇱,+56,2015,CLP,2100,3.26873686668223,642.45
China,CN,CHN,🇨🇳,+86,2015,CNY,17,2.73787283385943,6.2092
Colombia,CO,COL,🇨🇴,+57,2015,COP,7900,2.91631289453284,2708.9
Costa Rica,CR,CRI,🇨🇷,+506,2015,CRC,2150,4.03150196887306,533.3
Czech Republic,CZ,CZE,🇨🇿,+420,2015,CZK,70,2.83374996204799,24.70225
Denmark,DK,DNK,🇩🇰,+45,2015,DKK,34.59,5.07851212368137,6.81105
Egypt,EG,EGY,🇪🇬,+20,2015,EGP,16.93,2.16218287239545,7.83005
Euro area,,EUZ,,,2015,EUR,3.7,4.05390499827466,0.912700224
Hong Kong,HK,HKG,🇭🇰,+852,2015,HKD,19.2,2.47716364762347,7.7508
Hungary,HU,HUN,🇭🇺,+36,2015,HUF,900,3.18158582964093,282.8778
India,IN,IND,🇮🇳,+91,2015,INR,116.25,1.83272899259026,63.43
Indonesia,ID,IDN,🇮🇩,+62,2015,IDR,30500,2.28558582187418,13344.5
Israel,IL,ISR,🇮🇱,+972,2015,ILS,17.5,4.63165138221711,3.77835
Japan,JP,JPN,🇯🇵,+81,2015,JPY,370,2.98543591398717,123.935
Malaysia,MY,MYS,🇲🇾,+60,2015,MYR,7.65,2.00972021542099,3.8065
Mexico,MX,MEX,🇲🇽,+52,2015,MXN,49,3.1131371209835,15.73975
New Zealand,NZ,NZL,🇳🇿,+64,2015,NZD,5.9,3.90963499998182,1.509092281
Norway,NO,NOR,🇳🇴,+47,2015,NOK,46,5.6493358960031,8.14255
Pakistan,PK,PAK,🇵🇰,+92,2015,PKR,350,3.44149459193707,101.7
Peru,PE,PER,🇵🇪,+51,2015,PEN,10,3.14119679597927,3.1835
Philippines,PH,PHL,🇵🇭,+63,2015,PHP,163,3.6051976776334,45.2125
Poland,PL,POL,🇵🇱,+48,2015,PLN,9.6,2.5439898240407,3.7736
Russia,RU,RUS,🇷🇺,+7,2015,RUB,107,1.88330546510605,56.815
Saudi Arabia,SA,SAU,🇸🇦,+966,2015,SAR,12,3.19953073549213,3.75055
Singapore,SG,SGP,🇸🇬,+65,2015,SGD,4.7,3.43969555035129,1.3664
South Africa,ZA,ZAF,🇿🇦,+27,2015,ZAR,26,2.0943167492982,12.41455
South Korea,KR,KOR,🇰🇷,+82,2015,KRW,4300,3.76038478355925,1143.5
Sri Lanka,LK,LKA,🇱🇰,+94,2015,LKR,350,2.61486738886814,133.85
Sweden,SE,SWE,🇸🇪,+46,2015,SEK,43.7,5.12751315611928,8.52265
Switzerland,CH,CHE,🇨🇭,+41,2015,CHF,6.5,6.82199832073888,0.9528
Taiwan,TW,TWN,🇹🇼,+886,2015,TWD,79,2.54674403610574,31.02
Thailand,TH,THA,🇹🇭,+66,2015,THB,108,3.16831683168317,34.0875
Turkey,TR,TUR,🇹🇷,+90,2015,TRY,10.25,3.8742109838606,2.6457
UAE,AE,ARE,🇦🇪,+971,2015,AED,13,3.53938931921208,3.67295
Ukraine,UA,UKR,🇺🇦,+380,2015,UAH,34,1.54897494305239,21.95
United States,US,USA,🇺🇸,+1,2015,USD,4.29,4.29,1
Uruguay,UY,URY,🇺🇾,+598,2015,UYU,113,4.13238251965624,27.345
Venezuela,VE,VEN,🇻🇪,+58,2015,VEF,132,0.67005076142132,197
Vietnam,VN,VNM,🇻🇳,+84,2015,VND,60000,2.75103163686382,21810
Argentina,AR,ARG,🇦🇷,+54,2016,ARS,50,3.3478406427854,14.935
Australia,AU,AUS,🇦🇺,+61,2016,AUD,5.75,4.30473750039754,1.335737661
Brazil,BR,BRA,🇧🇷,+55,2016,BRL,15.5,4.78181060944948,3.24145
Britain,GB,GBR,🇬🇧,+44,2016,GBP,2.99,3.94022199859098,0.758840492
Canada,CA,CAN,🇨🇦,+1,2016,CAD,6,4.60475825019187,1.303
Chile,CL,CHL,🇨🇱,+56,2016,CLP,2300,3.53237498464185,651.12
China,CN,CHN,🇨🇳,+86,2016,CNY,18.6,2.78526504941599,6.678
Colombia,CO,COL,🇨🇴,+57,2016,COP,8900,3.03857972004097,2929
Costa Rica,CR,CRI,🇨🇷,+506,2016,CRC,2250,4.11793773678142,546.39
Czech Republic,CZ,CZE,🇨🇿,+420,2016,CZK,75,3.05541919332859,24.54655
Denmark,DK,DNK,🇩🇰,+45,2016,DKK,30,4.43974160703847,6.75715
Egypt,EG,EGY,🇪🇬,+20,2016,EGP,23,2.5901046740128,8.87995
Euro area,,EUZ,,,2016,EUR,3.82,4.20562899815015,0.908306463
Hong Kong,HK,HKG,🇭🇰,+852,2016,HKD,19.2,2.4756943549011,7.7554
Hungary,HU,HUN,🇭🇺,+36,2016,HUF,900,3.15082252221943,285.6397
India,IN,IND,🇮🇳,+91,2016,INR,162,2.41071428571429,67.2
Indonesia,ID,IDN,🇮🇩,+62,2016,IDR,31000,2.36415633937083,13112.5
Israel,IL,ISR,🇮🇱,+972,2016,ILS,16.9,4.37965662455458,3.85875
Japan,JP,JPN,🇯🇵,+81,2016,JPY,370,3.46685406418365,106.725
Malaysia,MY,MYS,🇲🇾,+60,2016,MYR,8,1.9865905140303,4.027
Mexico,MX,MEX,🇲🇽,+52,2016,MXN,44,2.37388724035608,18.535
New Zealand,NZ,NZL,🇳🇿,+64,2016,NZD,6,4.22130000059584,1.421363087
Norway,NO,NOR,🇳🇴,+47,2016,NOK,46.8,5.51006351878779,8.49355
Pakistan,PK,PAK,🇵🇰,+92,2016,PKR,375,3.57756153405839,104.82
Peru,PE,PER,🇵🇪,+51,2016,PEN,10,3.02494063554003,3.30585
Philippines,PH,PHL,🇵🇭,+63,2016,PHP,133,2.82288018677704,47.115
Poland,PL,POL,🇵🇱,+48,2016,PLN,9.6,2.42078852142776,3.96565
Russia,RU,RUS,🇷🇺,+7,2016,RUB,130,2.05022903423961,63.40755
Saudi Arabia,SA,SAU,🇸🇦,+966,2016,SAR,12,3.19957339021464,3.7505
Singapore,SG,SGP,🇸🇬,+65,2016,SGD,5.45,4.01414156293732,1.3577
South Africa,ZA,ZAF,🇿🇦,+27,2016,ZAR,30,2.10176022418776,14.27375
South Korea,KR,KOR,🇰🇷,+82,2016,KRW,4400,3.85643542661817,1140.95
Sri Lanka,LK,LKA,🇱🇰,+94,2016,LKR,530,3.62517099863201,146.2
Sweden,SE,SWE,🇸🇪,+46,2016,SEK,44.94,5.22871254297632,8.59485
Switzerland,CH,CHE,🇨🇭,+41,2016,CHF,6.5,6.59162356758949,0.9861
Taiwan,TW,TWN,🇹🇼,+886,2016,TWD,69,2.15429766773861,32.029
Thailand,TH,THA,🇹🇭,+66,2016,THB,119,3.4034034034034,34.965
Turkey,TR,TUR,🇹🇷,+90,2016,TRY,10.75,3.53263995005011,3.04305
UAE,AE,ARE,🇦🇪,+971,2016,AED,13,3.53924477961395,3.6731
Ukraine,UA,UKR,🇺🇦,+380,2016,UAH,39,1.57258064516129,24.8
United States,US,USA,🇺🇸,+1,2016,USD,4.5,4.5,1
Uruguay,UY,URY,🇺🇾,+598,2016,UYU,123,4.07757334659373,30.165
Venezuela,VE,VEN,🇻🇪,+58,2016,VEF,2170,3.38322366959406,641.4001
Vietnam,VN,VNM,🇻🇳,+84,2016,VND,60000,2.69058295964126,22300
Argentina,AR,ARG,🇦🇷,+54,2017,ARS,70,4.12553410932665,16.9675
Australia,AU,AUS,🇦🇺,+61,2017,AUD,5.9,4.52795500055083,1.303016483
Brazil,BR,BRA,🇧🇷,+55,2017,BRL,16.5,5.10156757258139,3.2343
Britain,GB,GBR,🇬🇧,+44,2017,GBP,3.19,4.11143150160695,0.775885479
Canada,CA,CAN,🇨🇦,+1,2017,CAD,5.97,4.65569679482181,1.2823
Chile,CL,CHL,🇨🇱,+56,2017,CLP,2550,3.84409554461789,663.355
China,CN,CHN,🇨🇳,+86,2017,CNY,19.8,2.9171270718232,6.7875
Colombia,CO,COL,🇨🇴,+57,2017,COP,9900,3.24360452925142,3052.16
Costa Rica,CR,CRI,🇨🇷,+506,2017,CRC,2290,4.00003493480292,572.495
Czech Republic,CZ,CZE,🇨🇿,+420,2017,CZK,75,3.28139971386194,22.8561
Denmark,DK,DNK,🇩🇰,+45,2017,DKK,30,4.60649054517816,6.51255
Egypt,EG,EGY,🇪🇬,+20,2017,EGP,31.37,1.75398378529494,17.885
Euro area,,EUZ,,,2017,EUR,3.91,4.46502449985779,0.875695083
Hong Kong,HK,HKG,🇭🇰,+852,2017,HKD,19.2,2.45791461307047,7.8115
Hungary,HU,HUN,🇭🇺,+36,2017,HUF,862,3.20894752849616,268.6239
India,IN,IND,🇮🇳,+91,2017,INR,178,2.75723192502808,64.5575
Indonesia,ID,IDN,🇮🇩,+62,2017,IDR,32126,2.40293204682299,13369.5
Israel,IL,ISR,🇮🇱,+972,2017,ILS,16.9,4.77333709927976,3.5405
Japan,JP,JPN,🇯🇵,+81,2017,JPY,380,3.36104723155846,113.06
Malaysia,MY,MYS,🇲🇾,+60,2017,MYR,8.6,2.00302783277047,4.2935
Mexico,MX,MEX,🇲🇽,+52,2017,MXN,49,2.75424026530641,17.79075
New Zealand,NZ,NZL,🇳🇿,+64,2017,NZD,6.1,4.43225999862689,1.376273053
Norway,NO,NOR,🇳🇴,+47,2017,NOK,49,5.91416018925313,8.2852
Pakistan,PK,PAK,🇵🇰,+92,2017,PKR,375,3.56633380884451,105.15
Peru,PE,PER,🇵🇪,+51,2017,PEN,10.5,3.22927879440258,3.2515
Philippines,PH,PHL,🇵🇭,+63,2017,PHP,134,2.64945182050953,50.5765
Poland,PL,POL,🇵🇱,+48,2017,PLN,10.1,2.72339966564202,3.7086
Russia,RU,RUS,🇷🇺,+7,2017,RUB,137,2.27813538775694,60.1369
Saudi Arabia,SA,SAU,🇸🇦,+966,2017,SAR,12,3.1998720051198,3.75015
Singapore,SG,SGP,🇸🇬,+65,2017,SGD,5.6,4.06474559047688,1.3777
South Africa,ZA,ZAF,🇿🇦,+27,2017,ZAR,30,2.26073850791259,13.27
South Korea,KR,KOR,🇰🇷,+82,2017,KRW,4400,3.84396977241952,1144.65
Sri Lanka,LK,LKA,🇱🇰,+94,2017,LKR,580,3.77297121483168,153.725
Sweden,SE,SWE,🇸🇪,+46,2017,SEK,48.97,5.81892070131244,8.41565
Switzerland,CH,CHE,🇨🇭,+41,2017,CHF,6.5,6.74168957112483,0.96415
Taiwan,TW,TWN,🇹🇼,+886,2017,TWD,69,2.26396522024444,30.4775
Thailand,TH,THA,🇹🇭,+66,2017,THB,119,3.49624667636214,34.0365
Turkey,TR,TUR,🇹🇷,+90,2017,TRY,10.75,3.00611009353896,3.57605
UAE,AE,ARE,🇦🇪,+971,2017,AED,14,3.81159814865233,3.673
Ukraine,UA,UKR,🇺🇦,+380,2017,UAH,44,1.69785838317577,25.915
United States,US,USA,🇺🇸,+1,2017,USD,4.5,4.5,1
Uruguay,UY,URY,🇺🇾,+598,2017,UYU,130,4.52882773036057,28.705
Venezuela,VE,VEN,🇻🇪,+58,2017,VEF,10950,4.05555555555556,2700
Vietnam,VN,VNM,🇻🇳,+84,2017,VND,60000,2.63939293962389,22732.5
Argentina,AR,ARG,🇦🇷,+54,2018,ARS,75,2.70513976555455,27.725
Australia,AU,AUS,🇦🇺,+61,2018,AUD,6.05,4.51541749972637,1.339853956
Brazil,BR,BRA,🇧🇷,+55,2018,BRL,16.9,4.40293355217737,3.83835
Britain,GB,GBR,🇬🇧,+44,2018,GBP,3.19,4.23153499830104,0.753863551
Canada,CA,CAN,🇨🇦,+1,2018,CAD,6.65,5.06724578047015,1.31235
Chile,CL,CHL,🇨🇱,+56,2018,CLP,2640,4.05075721541129,651.73
China,CN,CHN,🇨🇳,+86,2018,CNY,20.5,3.09574146783449,6.622
Colombia,CO,COL,🇨🇴,+57,2018,COP,11900,4.14046978674841,2874.07
Costa Rica,CR,CRI,🇨🇷,+506,2018,CRC,2290,4.03311025008806,567.8
Czech Republic,CZ,CZE,🇨🇿,+420,2018,CZK,75,3.39908677868546,22.06475
Denmark,DK,DNK,🇩🇰,+45,2018,DKK,30,4.71764872387602,6.3591
Egypt,EG,EGY,🇪🇬,+20,2018,EGP,31.37,1.7520245741413,17.905
Euro area,,EUZ,,,2018,EUR,4.04,4.73508200138241,0.853205921
Hong Kong,HK,HKG,🇭🇰,+852,2018,HKD,20,2.54833880164368,7.84825
Hungary,HU,HUN,🇭🇺,+36,2018,HUF,850,3.07491728472504,276.4302
India,IN,IND,🇮🇳,+91,2018,INR,173,2.51362150381402,68.825
Indonesia,ID,IDN,🇮🇩,+62,2018,IDR,31500,2.19359331476323,14360
Israel,IL,ISR,🇮🇱,+972,2018,ILS,17,4.67688244518419,3.6349
Japan,JP,JPN,🇯🇵,+81,2018,JPY,390,3.50561797752809,111.25
Malaysia,MY,MYS,🇲🇾,+60,2018,MYR,8.45,2.10068365444375,4.0225
Mexico,MX,MEX,🇲🇽,+52,2018,MXN,49,2.57197595989817,19.0515
New Zealand,NZ,NZL,🇳🇿,+64,2018,NZD,6.2,4.23398000016174,1.464343242
Norway,NO,NOR,🇳🇴,+47,2018,NOK,42,5.22290617422123,8.0415
Pakistan,PK,PAK,🇵🇰,+92,2018,PKR,375,3.08667380031278,121.49
Peru,PE,PER,🇵🇪,+51,2018,PEN,10.5,3.20953690967446,3.2715
Philippines,PH,PHL,🇵🇭,+63,2018,PHP,140,2.61745844784714,53.487
Poland,PL,POL,🇵🇱,+48,2018,PLN,10.1,2.73939163807483,3.68695
Russia,RU,RUS,🇷🇺,+7,2018,RUB,130,2.09202832606353,62.14065
Saudi Arabia,SA,SAU,🇸🇦,+966,2018,SAR,12,3.19965870307167,3.7504
Singapore,SG,SGP,🇸🇬,+65,2018,SGD,5.8,4.27775933915994,1.35585
South Africa,ZA,ZAF,🇿🇦,+27,2018,ZAR,31,2.32002933714517,13.3619
South Korea,KR,KOR,🇰🇷,+82,2018,KRW,4500,4.03225806451613,1116
Sri Lanka,LK,LKA,🇱🇰,+94,2018,LKR,580,3.64161486783449,159.27
Sweden,SE,SWE,🇸🇪,+46,2018,SEK,51,5.82684002467838,8.7526
Switzerland,CH,CHE,🇨🇭,+41,2018,CHF,6.5,6.54417316889001,0.99325
Taiwan,TW,TWN,🇹🇼,+886,2018,TWD,69,2.27179191703021,30.3725
Thailand,TH,THA,🇹🇭,+66,2018,THB,119,3.5875791377751,33.17
Turkey,TR,TUR,🇹🇷,+90,2018,TRY,10.75,2.28419654714475,4.70625
United Arab Emirates,AE,ARE,🇦🇪,+971,2018,AED,14,3.81144249486136,3.67315
Ukraine,UA,UKR,🇺🇦,+380,2018,UAH,50,1.90846978892324,26.199
United States,US,USA,🇺🇸,+1,2018,USD,4.62,4.62,1
Uruguay,UY,URY,🇺🇾,+598,2018,UYU,140,4.47141488342383,31.31
Vietnam,VN,VNM,🇻🇳,+84,2018,VND,65000,2.82124178042058,23039.5
Azerbaijan,AZ,AZE,🇦🇿,+994,2018,AZN,3.95,2.32114000293815,1.70175
Bahrain,BH,BHR,🇧🇭,+973,2018,BHD,1.2,3.16163878276907,0.37955
Guatemala,GT,GTM,🇬🇹,+502,2018,GTQ,25,3.33907654499072,7.4871
Honduras,HN,HND,🇭🇳,+504,2018,HNL,85,3.54275901736369,23.9926
Jordan,JO,JOR,🇯🇴,+962,2018,JOD,1.95,2.74628547285402,0.71005
Kuwait,KW,KWT,🇰🇼,+965,2018,KWD,1.05,3.47107438016529,0.3025
Lebanon,LB,LBN,🇱🇧,+961,2018,LBP,6500,4.3017868960953,1511
Moldova,MD,MDA,🇲🇩,+373,2018,MDL,43,2.58217935937932,16.6526
Nicaragua,NI,NIC,🇳🇮,+505,2018,NIO,103,3.26081450714213,31.5872
Oman,OM,OMN,🇴🇲,+968,2018,OMR,1.05,2.72727272727273,0.385
Qatar,QA,QAT,🇶🇦,+974,2018,QAR,12,3.29579785773139,3.641
Romania,RO,ROU,🇷🇴,+40,2018,RON,9.2,2.31548480463097,3.97325
Argentina,AR,ARG,🇦🇷,+54,2019,ARS,120,2.87050437153895,41.8045
Australia,AU,AUS,🇦🇺,+61,2019,AUD,6.15,4.26010500144716,1.443626389
Azerbaijan,AZ,AZE,🇦🇿,+994,2019,AZN,3.95,2.32832301797819,1.6965
Bahrain,BH,BHR,🇧🇭,+973,2019,BHD,1.4,3.71352785145889,0.377
Brazil,BR,BRA,🇧🇷,+55,2019,BRL,17.5,4.59643316786174,3.8073
Britain,GB,GBR,🇬🇧,+44,2019,GBP,3.29,4.10131399929868,0.802181935
Canada,CA,CAN,🇨🇦,+1,2019,CAD,6.77,5.1559346559537,1.31305
Chile,CL,CHL,🇨🇱,+56,2019,CLP,2640,3.82747372236318,689.75
China,CN,CHN,🇨🇳,+86,2019,CNY,21,3.05028614589083,6.8846
Colombia,CO,COL,🇨🇴,+57,2019,COP,11900,3.68763557483731,3227
Costa Rica,CR,CRI,🇨🇷,+506,2019,CRC,2290,3.93514739618686,581.935
Czech Republic,CZ,CZE,🇨🇿,+420,2019,CZK,85,3.72818463724764,22.7993
Denmark,DK,DNK,🇩🇰,+45,2019,DKK,30,4.50497800069076,6.6593
Egypt,EG,EGY,🇪🇬,+20,2019,EGP,42,2.52783629250677,16.615
Euro area,,EUZ,,,2019,EUR,4.08,4.57225200199556,0.892339267
Guatemala,GT,GTM,🇬🇹,+502,2019,GTQ,25,3.25512356449051,7.6802
Honduras,HN,HND,🇭🇳,+504,2019,HNL,86,3.51258403653087,24.4834
Hong Kong,HK,HKG,🇭🇰,+852,2019,HKD,20.5,2.6246719160105,7.8105
Hungary,HU,HUN,🇭🇺,+36,2019,HUF,900,3.09766738762007,290.5412
India,IN,IND,🇮🇳,+91,2019,INR,183,2.66941290420454,68.5544
Indonesia,ID,IDN,🇮🇩,+62,2019,IDR,32000,2.26468506723284,14130
Israel,IL,ISR,🇮🇱,+972,2019,ILS,17,4.76631059522808,3.5667
Japan,JP,JPN,🇯🇵,+81,2019,JPY,390,3.58571231554268,108.765
Jordan,JO,JOR,🇯🇴,+962,2019,JOD,2.3,3.24400564174894,0.709
Kuwait,KW,KWT,🇰🇼,+965,2019,KWD,1.1,3.61247947454844,0.3045
Lebanon,LB,LBN,🇱🇧,+961,2019,LBP,6500,4.31320504313205,1507
Malaysia,MY,MYS,🇲🇾,+60,2019,MYR,8.85,2.13639106819553,4.1425
Mexico,MX,MEX,🇲🇽,+52,2019,MXN,50,2.64616768764637,18.89525
Moldova,MD,MDA,🇲🇩,+373,2019,MDL,43,2.41380471758485,17.8142
New Zealand,NZ,NZL,🇳🇿,+64,2019,NZD,6.4,4.2288000011016,1.513431706
Nicaragua,NI,NIC,🇳🇮,+505,2019,NIO,110,3.31703963259263,33.1621
Norway,NO,NOR,🇳🇴,+47,2019,NOK,42,4.85394816676779,8.65275
Oman,OM,OMN,🇴🇲,+968,2019,OMR,1.21,3.14285714285714,0.385
Pakistan,PK,PAK,🇵🇰,+92,2019,PKR,480,3.0485868529692,157.45
Peru,PE,PER,🇵🇪,+51,2019,PEN,10.5,3.19459656809054,3.2868
Philippines,PH,PHL,🇵🇭,+63,2019,PHP,142,2.76708725093779,51.3175
Poland,PL,POL,🇵🇱,+48,2019,PLN,10.8,2.83632066180815,3.80775
Qatar,QA,QAT,🇶🇦,+974,2019,QAR,13,3.56971236356147,3.64175
Romania,RO,ROU,🇷🇴,+40,2019,RON,9.3,2.20420932878271,4.2192
Russia,RU,RUS,🇷🇺,+7,2019,RUB,130,2.03638072487322,63.83875
Saudi Arabia,SA,SAU,🇸🇦,+966,2019,SAR,13,3.46634314130681,3.75035
Singapore,SG,SGP,🇸🇬,+65,2019,SGD,5.8,4.26063321824726,1.3613
South Africa,ZA,ZAF,🇿🇦,+27,2019,ZAR,31,2.18694885361552,14.175
South Korea,KR,KOR,🇰🇷,+82,2019,KRW,4500,3.81178264368303,1180.55
Sri Lanka,LK,LKA,🇱🇰,+94,2019,LKR,640,3.64309093496513,175.675
Sweden,SE,SWE,🇸🇪,+46,2019,SEK,51,5.38255734798233,9.47505
Switzerland,CH,CHE,🇨🇭,+41,2019,CHF,6.5,6.54219717175784,0.99355
Taiwan,TW,TWN,🇹🇼,+886,2019,TWD,72,2.30976517387399,31.172
Thailand,TH,THA,🇹🇭,+66,2019,THB,119,3.86018976563134,30.8275
Turkey,TR,TUR,🇹🇷,+90,2019,TRY,13.99,2.44494931842013,5.722
Ukraine,UA,UKR,🇺🇦,+380,2019,UAH,57,2.22243883419437,25.6475
United Arab Emirates,AE,ARE,🇦🇪,+971,2019,AED,14.75,4.01562691422893,3.67315
United States,US,USA,🇺🇸,+1,2019,USD,4.71,4.71,1
Uruguay,UY,URY,🇺🇾,+598,2019,UYU,164,4.66313140647436,35.1695
Vietnam,VN,VNM,🇻🇳,+84,2019,VND,65000,2.79798545047566,23231
Argentina,AR,ARG,🇦🇷,+54,2020,ARS,250,3.50923243962541,71.24065
Australia,AU,AUS,🇦🇺,+61,2020,AUD,6.55,4.57844999846622,1.430615165
Azerbaijan,AZ,AZE,🇦🇿,+994,2020,AZN,3.95,2.32489699823426,1.699
Bahrain,BH,BHR,🇧🇭,+973,2020,BHD,1.4,3.71303540644477,0.37705
Brazil,BR,BRA,🇧🇷,+55,2020,BRL,20.9,3.91352788622682,5.34045
Britain,GB,GBR,🇬🇧,+44,2020,GBP,3.39,4.27733249849224,0.79255003
Canada,CA,CAN,🇨🇦,+1,2020,CAD,6.88,5.0767414403778,1.3552
Chile,CL,CHL,🇨🇱,+56,2020,CLP,2740,3.47848165545258,787.7
China,CN,CHN,🇨🇳,+86,2020,CNY,21.7,3.09845077461269,7.0035
Colombia,CO,COL,🇨🇴,+57,2020,COP,11900,3.2900102570908,3617.01
Costa Rica,CR,CRI,🇨🇷,+506,2020,CRC,2350,4.039015167791,581.825
Czech Republic,CZ,CZE,🇨🇿,+420,2020,CZK,89,3.80124202379834,23.4134
Denmark,DK,DNK,🇩🇰,+45,2020,DKK,30,4.58060723583256,6.54935
Egypt,EG,EGY,🇪🇬,+20,2020,EGP,42,2.63322884012539,15.95
Euro area,,EUZ,,,2020,EUR,4.21,4.78613850072343,0.879623521
Guatemala,GT,GTM,🇬🇹,+502,2020,GTQ,25,3.24970752632263,7.693
Honduras,HN,HND,🇭🇳,+504,2020,HNL,87,3.52359390377754,24.6907
Hong Kong,HK,HKG,🇭🇰,+852,2020,HKD,20.5,2.64483708448642,7.75095
Hungary,HU,HUN,🇭🇺,+36,2020,HUF,900,2.89037807590422,311.37795
India,IN,IND,🇮🇳,+91,2020,INR,190,2.52667974334253,75.1975
Indonesia,ID,IDN,🇮🇩,+62,2020,IDR,34000,2.35538621406304,14435
Israel,IL,ISR,🇮🇱,+972,2020,ILS,17,4.94660575552129,3.4367
Japan,JP,JPN,🇯🇵,+81,2020,JPY,390,3.63551619669075,107.275
Jordan,JO,JOR,🇯🇴,+962,2020,JOD,2.3,3.24400564174894,0.709
Kuwait,KW,KWT,🇰🇼,+965,2020,KWD,1.15,3.73619233268356,0.3078
Lebanon,LB,LBN,🇱🇧,+961,2020,LBP,9000,5.95238095238095,1512
Malaysia,MY,MYS,🇲🇾,+60,2020,MYR,9.99,2.34204665338178,4.2655
Mexico,MX,MEX,🇲🇽,+52,2020,MXN,50,2.2285612408629,22.436
Moldova,MD,MDA,🇲🇩,+373,2020,MDL,47,2.75256222547584,17.075
New Zealand,NZ,NZL,🇳🇿,+64,2020,NZD,6.6,4.34840999993043,1.51779616
Nicaragua,NI,NIC,🇳🇮,+505,2020,NIO,120,3.49088878028346,34.3752
Norway,NO,NOR,🇳🇴,+47,2020,NOK,52,5.54900464729139,9.37105
Oman,OM,OMN,🇴🇲,+968,2020,OMR,1.1,2.85714285714286,0.385
Pakistan,PK,PAK,🇵🇰,+92,2020,PKR,550,3.3033033033033,166.5
Peru,PE,PER,🇵🇪,+51,2020,PEN,11.9,3.40262488205187,3.4973
Philippines,PH,PHL,🇵🇭,+63,2020,PHP,142,2.87298183142476,49.426
Poland,PL,POL,🇵🇱,+48,2020,PLN,11,2.78943564645171,3.94345
Qatar,QA,QAT,🇶🇦,+974,2020,QAR,13,3.57044767920901,3.641
Romania,RO,ROU,🇷🇴,+40,2020,RON,9.9,2.32391638595791,4.26005
Russia,RU,RUS,🇷🇺,+7,2020,RUB,135,1.91258766026776,70.585
Saudi Arabia,SA,SAU,🇸🇦,+966,2020,SAR,14,3.7322882926114,3.75105
Singapore,SG,SGP,🇸🇬,+65,2020,SGD,5.9,4.2519458057077,1.3876
South Africa,ZA,ZAF,🇿🇦,+27,2020,ZAR,31,1.85934922777028,16.6725
South Korea,KR,KOR,🇰🇷,+82,2020,KRW,4500,3.74703359840127,1200.95
Sri Lanka,LK,LKA,🇱🇰,+94,2020,LKR,680,3.65886467581383,185.85
Sweden,SE,SWE,🇸🇪,+46,2020,SEK,52.6,5.75593101637048,9.1384
Switzerland,CH,CHE,🇨🇭,+41,2020,CHF,6.5,6.90571049136786,0.94125
Taiwan,TW,TWN,🇹🇼,+886,2020,TWD,72,2.44428224670277,29.4565
Thailand,TH,THA,🇹🇭,+66,2020,THB,128,4.0783813923849,31.385
Turkey,TR,TUR,🇹🇷,+90,2020,TRY,13.99,2.03950725271521,6.8595
Ukraine,UA,UKR,🇺🇦,+380,2020,UAH,59,2.17471433837081,27.13
United Arab Emirates,AE,ARE,🇦🇪,+971,2020,AED,14.75,4.0158455737214,3.67295
United States,US,USA,🇺🇸,+1,2020,USD,4.82,4.82,1
Uruguay,UY,URY,🇺🇾,+598,2020,UYU,189,4.32741843159702,43.675
Vietnam,VN,VNM,🇻🇳,+84,2020,VND,66000,2.84728213977567,23180
Argentina,AR,ARG,🇦🇷,+54,2021,ARS,380,3.94461961669716,96.33375
Australia,AU,AUS,🇦🇺,+61,2021,AUD,6.55,4.79296250033191,1.366586949
Azerbaijan,AZ,AZE,🇦🇿,+994,2021,AZN,3.95,2.32489699823426,1.699
Bahrain,BH,BHR,🇧🇭,+973,2021,BHD,1.5,3.97877984084881,0.377
Brazil,BR,BRA,🇧🇷,+55,2021,BRL,22.9,4.36302668305183,5.24865
Britain,GB,GBR,🇬🇧,+44,2021,GBP,3.49,4.75041349933471,0.734672887
Canada,CA,CAN,🇨🇦,+1,2021,CAD,6.77,5.31355466603877,1.2741
Chile,CL,CHL,🇨🇱,+56,2021,CLP,2990,3.93791527555529,759.285
China,CN,CHN,🇨🇳,+86,2021,CNY,22.4,3.45695016744602,6.4797
Colombia,CO,COL,🇨🇴,+57,2021,COP,12950,3.37054378974053,3842.11
Costa Rica,CR,CRI,🇨🇷,+506,2021,CRC,2370,3.83656554537508,617.74
Czech Republic,CZ,CZE,🇨🇿,+420,2021,CZK,89,4.08244708299982,21.80065
Denmark,DK,DNK,🇩🇰,+45,2021,DKK,30,4.7439080314363,6.3239
Egypt,EG,EGY,🇪🇬,+20,2021,EGP,42.5,2.71218889597958,15.67
Euro area,,EUZ,,,2021,EUR,4.29,5.0461124993377,0.850159405
Guatemala,GT,GTM,🇬🇹,+502,2021,GTQ,26,3.35709120958579,7.7448
Honduras,HN,HND,🇭🇳,+504,2021,HNL,87,3.66408355795148,23.744
Hong Kong,HK,HKG,🇭🇰,+852,2021,HKD,21,2.70169434831273,7.7729
Hungary,HU,HUN,🇭🇺,+36,2021,HUF,908,2.97076625320777,305.64505
India,IN,IND,🇮🇳,+91,2021,INR,190,2.54640487837566,74.615
Indonesia,ID,IDN,🇮🇩,+62,2021,IDR,34000,2.34200103323575,14517.5
Israel,IL,ISR,🇮🇱,+972,2021,ILS,17,5.16395558998193,3.29205
Japan,JP,JPN,🇯🇵,+81,2021,JPY,390,3.54755082548779,109.935
Jordan,JO,JOR,🇯🇴,+962,2021,JOD,2.13,3.00423131170663,0.709
Kuwait,KW,KWT,🇰🇼,+965,2021,KWD,1.25,4.15558510638298,0.3008
Lebanon,LB,LBN,🇱🇧,+961,2021,LBP,37000,1.68181818181818,22000
Malaysia,MY,MYS,🇲🇾,+60,2021,MYR,9.99,2.36477689667416,4.2245
Mexico,MX,MEX,🇲🇽,+52,2021,MXN,64,3.17850535751977,20.13525
Moldova,MD,MDA,🇲🇩,+373,2021,MDL,52,2.89210233592881,17.98
New Zealand,NZ,NZL,🇳🇿,+64,2021,NZD,6.9,4.76272499912723,1.448750453
Nicaragua,NI,NIC,🇳🇮,+505,2021,NIO,128,3.63571292637965,35.2063
Norway,NO,NOR,🇳🇴,+47,2021,NOK,57,6.30224227146079,9.0444
Oman,OM,OMN,🇴🇲,+968,2021,OMR,1.15,2.98662511362161,0.38505
Pakistan,PK,PAK,🇵🇰,+92,2021,PKR,580,3.60024829298572,161.1
Peru,PE,PER,🇵🇪,+51,2021,PEN,12.9,3.26491356837337,3.9511
Philippines,PH,PHL,🇵🇭,+63,2021,PHP,142,2.82025819265144,50.35
Poland,PL,POL,🇵🇱,+48,2021,PLN,13.43,3.43755199201403,3.90685
Qatar,QA,QAT,🇶🇦,+974,2021,QAR,13,3.57044767920901,3.641
Romania,RO,ROU,🇷🇴,+40,2021,RON,10.6,2.53122238937842,4.1877
Russia,RU,RUS,🇷🇺,+7,2021,RUB,135,1.81135113377164,74.53
Saudi Arabia,SA,SAU,🇸🇦,+966,2021,SAR,14,3.73263657450609,3.7507
Singapore,SG,SGP,🇸🇬,+65,2021,SGD,5.9,4.31207747122236,1.36825
South Africa,ZA,ZAF,🇿🇦,+27,2021,ZAR,33.5,2.2847399829497,14.6625
South Korea,KR,KOR,🇰🇷,+82,2021,KRW,4600,3.99878297909332,1150.35
Sri Lanka,LK,LKA,🇱🇰,+94,2021,LKR,700,3.50877192982456,199.5
Sweden,SE,SWE,🇸🇪,+46,2021,SEK,54,6.19514713474445,8.7165
Switzerland,CH,CHE,🇨🇭,+41,2021,CHF,6.5,7.04416147385532,0.92275
Taiwan,TW,TWN,🇹🇼,+886,2021,TWD,72,2.56707371423478,28.0475
Thailand,TH,THA,🇹🇭,+66,2021,THB,128,3.90124961901859,32.81
Turkey,TR,TUR,🇹🇷,+90,2021,TRY,19.99,2.33603085103275,8.55725
Ukraine,UA,UKR,🇺🇦,+380,2021,UAH,65,2.38795003673769,27.22
United Arab Emirates,AE,ARE,🇦🇪,+971,2021,AED,14.75,4.01562691422893,3.67315
United States,US,USA,🇺🇸,+1,2021,USD,4.93,4.93,1
Uruguay,UY,URY,🇺🇾,+598,2021,UYU,225,5.11363636363636,44
Vietnam,VN,VNM,🇻🇳,+84,2021,VND,69000,2.99628720932757,23028.5
Venezuela,VE,VEN,🇻🇪,+58,2021,VES,16020000,4.43277488815627,3613989.071
Argentina,AR,ARG,🇦🇷,+54,2022,ARS,590,4.56956976338923,129.115
Australia,AU,AUS,🇦🇺,+61,2022,AUD,6.7,4.62567999999999,1.44843568945539
Azerbaijan,AZ,AZE,🇦🇿,+994,2022,AZN,4.7,2.76755483586044,1.69825
Bahrain,BH,BHR,🇧🇭,+973,2022,BHD,1.6,4.24403183023873,0.377
Brazil,BR,BRA,🇧🇷,+55,2022,BRL,22.9,4.24722956368526,5.39175
Britain,GB,GBR,🇬🇧,+44,2022,GBP,3.69,4.44000577561728,0.831079999999998
Canada,CA,CAN,🇨🇦,+1,2022,CAD,5.88,4.56114494046465,1.28915
Chile,CL,CHL,🇨🇱,+56,2022,CLP,3400,3.66207650508652,928.435
China,CN,CHN,🇨🇳,+86,2022,CNY,24,3.55695198855847,6.74735
Colombia,CO,COL,🇨🇴,+57,2022,COP,14950,3.48071057716933,4295.1
Costa Rica,CR,CRI,🇨🇷,+506,2022,CRC,2650,3.9079493588751,678.105
Czech Republic,CZ,CZE,🇨🇿,+420,2022,CZK,95,3.97157190635452,23.92
Denmark,DK,DNK,🇩🇰,+45,2022,DKK,32,4.82123755141398,6.6373
Egypt,EG,EGY,🇪🇬,+20,2022,EGP,46,2.42808128793877,18.945
Euro area,,EUZ,,,2022,EUR,4.65,4.76507659988729,0.975849999999997
Guatemala,GT,GTM,🇬🇹,+502,2022,GTQ,26,3.36443276957518,7.7279
Honduras,HN,HND,🇭🇳,+504,2022,HNL,89,3.61568149502336,24.615
Hong Kong,HK,HKG,🇭🇰,+852,2022,HKD,21,2.67517627500812,7.84995
Hungary,HU,HUN,🇭🇺,+36,2022,HUF,1030,2.64750081706245,389.04615
India,IN,IND,🇮🇳,+91,2022,INR,191,2.38895427591546,79.9513
Indonesia,ID,IDN,🇮🇩,+62,2022,IDR,35000,2.33683859122016,14977.5
Israel,IL,ISR,🇮🇱,+972,2022,ILS,17,4.94552648038517,3.43745
Japan,JP,JPN,🇯🇵,+81,2022,JPY,390,2.82885431400283,137.865
Jordan,JO,JOR,🇯🇴,+962,2022,JOD,2.3,3.23875237625854,0.71015
Kuwait,KW,KWT,🇰🇼,+965,2022,KWD,1.3,4.22901756668835,0.3074
Lebanon,LB,LBN,🇱🇧,+961,2022,LBP,130000,5.078125,25600
Malaysia,MY,MYS,🇲🇾,+60,2022,MYR,10.9,2.44943820224719,4.45
Mexico,MX,MEX,🇲🇽,+52,2022,MXN,70,3.42927127985303,20.4125
Moldova,MD,MDA,🇲🇩,+373,2022,MDL,60,3.10913047984247,19.298
New Zealand,NZ,NZL,🇳🇿,+64,2022,NZD,7.1,4.427205,1.60372063186593
Nicaragua,NI,NIC,🇳🇮,+505,2022,NIO,139,3.87294511005851,35.89
Norway,NO,NOR,🇳🇴,+47,2022,NOK,62,6.26411319858754,9.89765
Oman,OM,OMN,🇴🇲,+968,2022,OMR,1.42,3.68831168831169,0.385
Pakistan,PK,PAK,🇵🇰,+92,2022,PKR,700,3.15670800450958,221.75
Peru,PE,PER,🇵🇪,+51,2022,PEN,13.9,3.57064875348395,3.89285
Philippines,PH,PHL,🇵🇭,+63,2022,PHP,155,2.75482093663912,56.265
Poland,PL,POL,🇵🇱,+48,2022,PLN,16.68,3.58829287181749,4.64845
Qatar,QA,QAT,🇶🇦,+974,2022,QAR,13,3.56971236356147,3.64175
Romania,RO,ROU,🇷🇴,+40,2022,RON,11,2.28132939285529,4.82175
Russia,RU,RUS,🇷🇺,+7,2022,RUB,135,1.74379177834469,77.4175
Saudi Arabia,SA,SAU,🇸🇦,+966,2022,SAR,17,4.52729693741678,3.755
Singapore,SG,SGP,🇸🇬,+65,2022,SGD,5.9,4.24033347707345,1.3914
South Africa,ZA,ZAF,🇿🇦,+27,2022,ZAR,39.9,2.34206471494607,17.03625
South Korea,KR,KOR,🇰🇷,+82,2022,KRW,4600,3.50222695953405,1313.45
Sri Lanka,LK,LKA,🇱🇰,+94,2022,LKR,1340,3.72222222222222,360
Sweden,SE,SWE,🇸🇪,+46,2022,SEK,57,5.58941345479684,10.19785
Switzerland,CH,CHE,🇨🇭,+41,2022,CHF,6.5,6.71175589860086,0.96845
Taiwan,TW,TWN,🇹🇼,+886,2022,TWD,75,2.5077321742038,29.9075
Thailand,TH,THA,🇹🇭,+66,2022,THB,128,3.49607374530557,36.6125
Turkey,TR,TUR,🇹🇷,+90,2022,TRY,47,2.6757756902932,17.565
Ukraine,UA,UKR,🇺🇦,+380,2022,UAH,69,2.43237812551776,28.3673
United Arab Emirates,AE,ARE,🇦🇪,+971,2022,AED,18,4.9005594805407,3.67305
United States,US,USA,🇺🇸,+1,2022,USD,5.15,5.15,1
Uruguay,UY,URY,🇺🇾,+598,2022,UYU,255,6.08446671438797,41.91
Venezuela,VE,VEN,🇻🇪,+58,2022,VES,10,1.7626736233519,5.6732
Vietnam,VN,VNM,🇻🇳,+84,2022,VND,69000,2.94657727292138,23417
Argentina,AR,ARG,🇦🇷,+54,2023,ARS,1650,5.99400599400599,275.275
Australia,AU,AUS,🇦🇺,+61,2023,AUD,7.45,5.02018250000001,1.48400979446464
Azerbaijan,AZ,AZE,🇦🇿,+994,2023,AZN,5.95,3.49486049926579,1.7025
Bahrain,BH,BHR,🇧🇭,+973,2023,BHD,1.7,4.50988194720785,0.37695
Brazil,BR,BRA,🇧🇷,+55,2023,BRL,22.9,4.81390777898067,4.75705
Britain,GB,GBR,🇬🇧,+44,2023,GBP,4.19,5.39107834433422,0.77721
Canada,CA,CAN,🇨🇦,+1,2023,CAD,7.05,5.35612535612536,1.31625
Chile,CL,CHL,🇨🇱,+56,2023,CLP,3900,4.64559472546322,839.505
China,CN,CHN,🇨🇳,+86,2023,CNY,25,3.49966053292831,7.14355
Colombia,CO,COL,🇨🇴,+57,2023,COP,18900,4.86747714024129,3882.915
Costa Rica,CR,CRI,🇨🇷,+506,2023,CRC,2950,5.4140857994953,544.875
Czech Republic,CZ,CZE,🇨🇿,+420,2023,CZK,105,4.84952613201796,21.6516
Denmark,DK,DNK,🇩🇰,+45,2023,DKK,38.2,5.65147278564349,6.7593
Egypt,EG,EGY,🇪🇬,+20,2023,EGP,81,2.61924009700889,30.925
Euro area,,EUZ,,,2023,EUR,5.28,5.82145337875831,0.90699
Guatemala,GT,GTM,🇬🇹,+502,2023,GTQ,29,3.68539440074216,7.8689
Honduras,HN,HND,🇭🇳,+504,2023,HNL,102,4.1429731925264,24.62
Hong Kong,HK,HKG,🇭🇰,+852,2023,HKD,23,2.94920948363189,7.7987
Hungary,HU,HUN,🇭🇺,+36,2023,HUF,1400,3.98778030178952,351.0725
India,IN,IND,🇮🇳,+91,2023,INR,209,2.54107050801957,82.2488
Indonesia,ID,IDN,🇮🇩,+62,2023,IDR,38000,2.51989389920424,15080
Israel,IL,ISR,🇮🇱,+972,2023,ILS,17,4.6401812400202,3.66365
Japan,JP,JPN,🇯🇵,+81,2023,JPY,450,3.16722972972973,142.08
Jordan,JO,JOR,🇯🇴,+962,2023,JOD,2.5,3.52634177304464,0.70895
Kuwait,KW,KWT,🇰🇼,+965,2023,KWD,1.4,4.55877564311299,0.3071
Lebanon,LB,LBN,🇱🇧,+961,2023,LBP,430000,5.02923976608187,85500
Malaysia,MY,MYS,🇲🇾,+60,2023,MYR,13.15,2.91638944333555,4.509
Mexico,MX,MEX,🇲🇽,+52,2023,MXN,89,5.32854363119294,16.7025
Moldova,MD,MDA,🇲🇩,+373,2023,MDL,63,3.56637418624399,17.665
New Zealand,NZ,NZL,🇳🇿,+64,2023,NZD,8.1,5.041035,1.60681288663935
Nicaragua,NI,NIC,🇳🇮,+505,2023,NIO,159,4.35020519835841,36.55
Norway,NO,NOR,🇳🇴,+47,2023,NOK,70,6.9193891167894,10.1165
Oman,OM,OMN,🇴🇲,+968,2023,OMR,1.42,3.68783274899364,0.38505
Pakistan,PK,PAK,🇵🇰,+92,2023,PKR,985,3.4410480349345,286.25
Peru,PE,PER,🇵🇪,+51,2023,PEN,14.9,4.13458203260493,3.60375
Philippines,PH,PHL,🇵🇭,+63,2023,PHP,155,2.82203004096495,54.925
Poland,PL,POL,🇵🇱,+48,2023,PLN,19.9,4.98172532919441,3.9946
Qatar,QA,QAT,🇶🇦,+974,2023,QAR,14,3.84615384615385,3.64
Romania,RO,ROU,🇷🇴,+40,2023,RON,14.5,3.23942717991108,4.4761
Saudi Arabia,SA,SAU,🇸🇦,+966,2023,SAR,19,5.06450581085404,3.7516
Singapore,SG,SGP,🇸🇬,+65,2023,SGD,6.45,4.85765928603705,1.3278
South Africa,ZA,ZAF,🇿🇦,+27,2023,ZAR,49.9,2.80593238209039,17.78375
South Korea,KR,KOR,🇰🇷,+82,2023,KRW,5200,4.07955124936257,1274.65
Sri Lanka,LK,LKA,🇱🇰,+94,2023,LKR,1820,5.55725190839695,327.5
Sweden,SE,SWE,🇸🇪,+46,2023,SEK,60.27,5.73590292648109,10.5075
Switzerland,CH,CHE,🇨🇭,+41,2023,CHF,6.7,7.72913422160697,0.86685
Taiwan,TW,TWN,🇹🇼,+886,2023,TWD,75,2.38652092978855,31.4265
Thailand,TH,THA,🇹🇭,+66,2023,THB,128,3.73954643391886,34.22875
Turkey,TR,TUR,🇹🇷,+90,2023,TRY,95,3.52809009627972,26.92675
Ukraine,UA,UKR,🇺🇦,+380,2023,UAH,105,2.84310526664943,36.93145
United Arab Emirates,AE,ARE,🇦🇪,+971,2023,AED,18,4.9005594805407,3.67305
United States,US,USA,🇺🇸,+1,2023,USD,5.58,5.58,1
Uruguay,UY,URY,🇺🇾,+598,2023,UYU,259,6.85820203892493,37.765
Venezuela,VE,VEN,🇻🇪,+58,2023,VES,56.3,2.71874290736475,20.7081
Vietnam,VN,VNM,🇻🇳,+84,2023,VND,74000,3.12401055408971,23687.5
Argentina,AR,ARG,🇦🇷,+54,2024,ARS,6100,6.54524556581073,931.9742
Australia,AU,AUS,🇦🇺,+61,2024,AUD,7.75,5.06075000000001,1.53139356814701
Azerbaijan,AZ,AZE,🇦🇿,+994,2024,AZN,6.15,3.61764705882353,1.7
Bahrain,BH,BHR,🇧🇭,+973,2024,BHD,1.7,4.50928381962865,0.377
Brazil,BR,BRA,🇧🇷,+55,2024,BRL,23.9,4.22803060457299,5.65275
Britain,GB,GBR,🇬🇧,+44,2024,GBP,4.59,5.89565083361163,0.77854
Canada,CA,CAN,🇨🇦,+1,2024,CAD,7.62,5.5159433928119,1.38145
Chile,CL,CHL,🇨🇱,+56,2024,CLP,4290,4.5404032386093,944.85
China,CN,CHN,🇨🇳,+86,2024,CNY,25.5,3.52814212186618,7.2276
Colombia,CO,COL,🇨🇴,+57,2024,COP,19900,4.90269746907482,4058.99
Costa Rica,CR,CRI,🇨🇷,+506,2024,CRC,2950,5.62472591377963,524.47
Czech Republic,CZ,CZE,🇨🇿,+420,2024,CZK,109,4.63180349254981,23.53295
Denmark,DK,DNK,🇩🇰,+45,2024,DKK,39,5.65553444800534,6.8959
Egypt,EG,EGY,🇪🇬,+20,2024,EGP,120,2.46913580246914,48.6
Euro area,,EUZ,,,2024,EUR,5.6,6.0597534978845,0.92413
Guatemala,GT,GTM,🇬🇹,+502,2024,GTQ,31,4.00216891734876,7.7458
Honduras,HN,HND,🇭🇳,+504,2024,HNL,102,4.11364135903418,24.79555
Hong Kong,HK,HKG,🇭🇰,+852,2024,HKD,23,2.9441884280594,7.812
Hungary,HU,HUN,🇭🇺,+36,2024,HUF,1420,3.89921196102491,364.17615
India,IN,IND,🇮🇳,+91,2024,INR,230,2.74696564800024,83.72875
Indonesia,ID,IDN,🇮🇩,+62,2024,IDR,40000,2.460024600246,16260
Israel,IL,ISR,🇮🇱,+972,2024,ILS,17,4.51977401129944,3.76125
Japan,JP,JPN,🇯🇵,+81,2024,JPY,480,3.19021666888209,150.46
Jordan,JO,JOR,🇯🇴,+962,2024,JOD,2.5,3.52609308885755,0.709
Kuwait,KW,KWT,🇰🇼,+965,2024,KWD,1.4,4.58190148911798,0.30555
Lebanon,LB,LBN,🇱🇧,+961,2024,LBP,460000,5.13679508654383,89550
Malaysia,MY,MYS,🇲🇾,+60,2024,MYR,13.15,2.86211774948308,4.5945
Mexico,MX,MEX,🇲🇽,+52,2024,MXN,95,5.09916534714581,18.6305
Moldova,MD,MDA,🇲🇩,+373,2024,MDL,63,3.57345433919456,17.63
New Zealand,NZ,NZL,🇳🇿,+64,2024,NZD,8.4,4.98918000000001,1.68364340432696
Nicaragua,NI,NIC,🇳🇮,+505,2024,NIO,159,4.34137990350669,36.6243
Norway,NO,NOR,🇳🇴,+47,2024,NOK,74,6.76757053363208,10.9345
Oman,OM,OMN,🇴🇲,+968,2024,OMR,1.53,3.97402597402597,0.385
Pakistan,PK,PAK,🇵🇰,+92,2024,PKR,1065,3.82062780269058,278.75
Peru,PE,PER,🇵🇪,+51,2024,PEN,16.9,4.54533229337565,3.7181
Philippines,PH,PHL,🇵🇭,+63,2024,PHP,167,2.86078920094903,58.3755
Poland,PL,POL,🇵🇱,+48,2024,PLN,20.9,5.26653983293225,3.96845
Qatar,QA,QAT,🇶🇦,+974,2024,QAR,14,3.84509750068662,3.641
Romania,RO,ROU,🇷🇴,+40,2024,RON,16.25,3.5324167164828,4.60025
Saudi Arabia,SA,SAU,🇸🇦,+966,2024,SAR,19,5.06410085556651,3.7519
Singapore,SG,SGP,🇸🇬,+65,2024,SGD,6.65,4.97159090909091,1.3376
South Africa,ZA,ZAF,🇿🇦,+27,2024,ZAR,51.9,2.85301999587714,18.19125
South Korea,KR,KOR,🇰🇷,+82,2024,KRW,5500,3.99491556201198,1376.75
Sri Lanka,LK,LKA,🇱🇰,+94,2024,LKR,1820,5.68838881075168,319.95
Sweden,SE,SWE,🇸🇪,+46,2024,SEK,60,5.59826826901545,10.7176
Switzerland,CH,CHE,🇨🇭,+41,2024,CHF,7.1,8.06589037205339,0.88025
Taiwan,TW,TWN,🇹🇼,+886,2024,TWD,75,2.28171585031944,32.87
Thailand,TH,THA,🇹🇭,+66,2024,THB,135,3.78734745406088,35.645
Turkey,TR,TUR,🇹🇷,+90,2024,TRY,155,4.68130859186238,33.1104
Ukraine,UA,UKR,🇺🇦,+380,2024,UAH,118,2.87454323995128,41.05
United Arab Emirates,AE,ARE,🇦🇪,+971,2024,AED,18,4.90062619112442,3.673
United States,US,USA,🇺🇸,+1,2024,USD,5.69,5.69,1
Uruguay,UY,URY,🇺🇾,+598,2024,UYU,285,7.07196029776675,40.3
Venezuela,VE,VEN,🇻🇪,+58,2024,VES,181.6,4.96621958839994,36.56705
Vietnam,VN,VNM,🇻🇳,+84,2024,VND,76000,3.01019902960689,25247.5`;
export default (() => data);